import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import EurekaUploadFile from '../estructura/EurekaUploadFile/EurekaUploadFile';

interface IFormArticulos {
  estadoArticulos: { show: boolean; id: number; accion: string };
  formDisabled: boolean;
  close: Function;
  disabledUpload: boolean;
}

interface IUseForm {
  idarticulo: number;
  articulo: string;
  sku: string;
  proveedor: number;
  stock_actual: number;
  stock_reservado: number;
  stock_disponible: number;
  json_imagenes: string;
  tipo: number;
  marca: number;
  modelo: number;
  precio_venta: number;
  disabled: boolean;
}

const FormArticulos: React.FC<IFormArticulos> = ({ estadoArticulos, formDisabled, close, disabledUpload }) => {
  const { register, setValue, getValues } = useForm<IUseForm>({
    defaultValues: {
      idarticulo: 0,
      articulo: '',
      sku: '',
      proveedor: 0,
      stock_actual: 0,
      stock_reservado: 0,
      stock_disponible: 0,
      json_imagenes: '[]',
      tipo: 0,
      marca: 0,
      modelo: 0,
      precio_venta: 0.0,
      disabled: true,
    },
  });
  const articulobyid = useCallback((registro: number, accion: string) => {
    GetEureka('/articulos/' + registro).then((response) => {
      if (response.info) {
        var registro = response.content[0];
        setValue('idarticulo', registro.idarticulo);
        setValue('articulo', registro.articulo);
        setValue('sku', registro.sku);
        setValue('proveedor', registro.proveedor);
        setValue('stock_actual', registro.stock_actual);
        setValue('stock_reservado', registro.stock_reservado);
        setValue('stock_disponible', registro.stock_disponible);
        setValue('tipo', registro.tipo);
        setValue('marca', registro.marca);
        setValue('modelo', registro.modelo);
        setValue('precio_venta', registro.precio_venta);
      } else {
        console.log(response);
      }
    });
  }, []);

  useEffect(() => {
    estadoArticulos.show && articulobyid(estadoArticulos.id, estadoArticulos.accion);
  }, [articulobyid, estadoArticulos.id, estadoArticulos.accion, estadoArticulos.show]);

  function handleFileUploaded(jsonInfo: any) {
    setValue('json_imagenes', JSON.stringify(jsonInfo));
  }
  function cerrar(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    close();
  }
  return (
    <>
      <form className='row'>
        <div className='col-12 col-lg-6 d-flex align-items-center justify-content-center'>
          <EurekaUploadFile titulo='800 x 600' deleteImages={setValue} onFileUploaded={handleFileUploaded} origen='modificar' alto={600} ancho={800} tipo='file' tipoAceptar='imagen' clase='file' disable={disabledUpload} images={JSON.parse(getValues().json_imagenes)} />
        </div>
        <div className='col-12 col-lg-6'>
          <div className='col-12'>
            <Form.Label htmlFor='articulo'>Articulo</Form.Label>
            <Form.Control type='text' disabled={formDisabled} {...register('articulo')} />
          </div>
          <div className='col-12'>
            <Form.Label htmlFor='sku'>SKU</Form.Label>
            <Form.Control type='text' disabled={formDisabled} {...register('sku')} />
          </div>
          <hr></hr>
          <div className='row'>
            <div className='col-12 col-md-4'>
              <Form.Label htmlFor='stock_reservado'>Stock actual</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('stock_actual')} />
            </div>
            <div className='col-12 col-md-4'>
              <Form.Label htmlFor='stock_reservado'>Stock reservado</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('stock_reservado')} />
            </div>
            <div className='col-12 col-md-4'>
              <Form.Label htmlFor='stock_disponible'>Stock disponible</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('stock_disponible')} />
            </div>
          </div>
          <hr></hr>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Form.Label htmlFor='tipo'>Tipo</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('tipo')} />
            </div>
            <div className='col-12 col-md-6'>
              <Form.Label htmlFor='marca'>Marca</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('marca')} />
            </div>
            <div className='col-12 col-md-6'>
              <Form.Label htmlFor='modelo'>Modelo</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('modelo')} />
            </div>
            <div className='col-12 col-md-6'>
              <Form.Label htmlFor='proveedor'>Proveedor</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('proveedor')} />
            </div>
          </div>
          <hr></hr>
          <div className='row'>
            <div className='col-12 col-md-6'>
              <Form.Label htmlFor='precio_venta'>Precio venta</Form.Label>
              <Form.Control disabled={formDisabled} type='text' {...register('precio_venta')} />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-sm background-roj' onClick={cerrar}>
            Cerrar
          </button>
        </div>
      </form>
    </>
  );
};
export default FormArticulos;
