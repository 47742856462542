import { useState } from 'react';
import { Card } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import FormVentaconsignacion from './Form-Ventaconsignacion';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltrosVentaconsignacion from './Filtros-Ventaconsignacion';
import CrearVentaconsignacion from './Crear-Ventaconsignacion';

function Ventaconsignacion() {
  const [modalVentaconsignacion, setmodalVentaconsignacion] = useState({ show: false, id: 0, accion: 'a' });
  const ModalClose = () => setmodalVentaconsignacion({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalVentaconsignacion({ show: true, id: id, accion: accion });

  const [offcanvasVentaconsignacion, setoffcanvasVentaconsignacion] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasShow = (id: number, accion: string) => setoffcanvasVentaconsignacion({ show: true, id: id, accion: accion });

  const [offcanvasFiltrosVentaconsignacion, setoffcanvasFiltrosVentaconsignacion] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosClose = () => setoffcanvasFiltrosVentaconsignacion({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosVentaconsignacion({ show: true, id: id, accion: accion });

  const [offcanvasCrearVentaconsignacion, setoffcanvasCrearVentaconsignacion] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasCrearClose = () => setoffcanvasCrearVentaconsignacion({ show: false, id: 0, accion: 'a' });
  const offCanvasCrearShow = (id: number, accion: string) => setoffcanvasCrearVentaconsignacion({ show: true, id: id, accion: accion });

  const [recargaGridVentaconsignacion, setRecargaGridVentaconsignacion] = useState('');
  const [formDisabled, setformDisabled] = useState(false);

  const manejo_acciones = (origen: string, registro: number, accion: string): void => {
    switch (accion) {
      case 'c':
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'i':
        offCanvasShow(registro, accion);
        break;
      case 'a':
        offCanvasFiltrosShow(registro, accion);
        break;
      case 'crear':
        offCanvasCrearShow(registro, accion);
        break;
      default:
        return;
    }
  };
  return (
    <>
      <PageLayout label='Adm. Venta consignacion'>
        <Card>
          <Card.Body>
            {Grid(manejo_acciones, 'ventaconsignacion', recargaGridVentaconsignacion, setRecargaGridVentaconsignacion)}
            <EurekaModals id='modal_Ventaconsignacion' manejador={modalVentaconsignacion} modalTitulo='Consultar venta consignacion' sizeModal='xl' handleClose={ModalClose}>
              <FormVentaconsignacion estadoVentaconsignacion={modalVentaconsignacion} formDisabled={formDisabled} close={ModalClose}></FormVentaconsignacion>
            </EurekaModals>
            <EurekaOffcanvas id='offcanvas_filtrosVentaconsignacion' manejador={offcanvasFiltrosVentaconsignacion} offcanvasTitulo='Filtros' handleClose={offCanvasFiltrosClose} posicion='start' size='w-20'>
              <FiltrosVentaconsignacion estadoVentaconsignacion={offcanvasVentaconsignacion} close={offCanvasFiltrosClose} setRecargaGridVentaconsignacion={setRecargaGridVentaconsignacion}></FiltrosVentaconsignacion>
            </EurekaOffcanvas>
            <EurekaOffcanvas id='offcanvas_crearVentaconsignacion' manejador={offcanvasCrearVentaconsignacion} offcanvasTitulo='Creacion' handleClose={offCanvasCrearClose} posicion='end' size='w-20'>
              <CrearVentaconsignacion estadoVentaconsignacion={offcanvasVentaconsignacion} close={offCanvasCrearClose} setRecargaGridVentaconsignacion={setRecargaGridVentaconsignacion}></CrearVentaconsignacion>
            </EurekaOffcanvas>
          </Card.Body>
        </Card>
      </PageLayout>
    </>
  );
}
export default Ventaconsignacion;
