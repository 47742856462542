import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment-timezone';
import { fechaMoment, fechaSelect } from '../../helpers/index';

interface IFormEntregas {
  estadoEntregas: { show: boolean; id: number; accion: string };
  formDisabled?: boolean;
  close: Function;
  setRecargaGridEntregas: Function;
}

interface IUseForm {
  idestado: { value: number; label: string; name: string };
  identrega: number;
  codinterno: string;
  nroventa: string;
  producto: string;
  fecha_inicio: string;
  fecha_fin: string;
}

const FiltrosEntregas: React.FC<IFormEntregas> = ({ estadoEntregas, formDisabled, close, setRecargaGridEntregas }) => {
  const [selectKey1, setSelectKey1] = useState(6);
  const localFiltros = localStorage.getItem('filtrosentregas')?.length ? JSON.parse(localStorage.getItem('filtrosentregas') ?? '{}') : null;
  const [combos, setCombos] = useState({
    estados: [],
  });
  const { register, handleSubmit, setValue, getValues, reset } = useForm<IUseForm>({
    defaultValues: {
      idestado: { value: 0, label: 'Select...', name: 'idestado' },
      identrega: 0,
      codinterno: '',
      nroventa: '',
      producto: '',
      fecha_inicio: '',
      fecha_fin: '',
    },
  });
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosentregas')?.length ? JSON.parse(localStorage.getItem('filtrosentregas') ?? '{}') : null;
    if (filtros) {
      for (let prop in filtros) {
        if (prop === ('idestado' || 'identrega' || 'codinterno' || 'nroventa' || 'producto' || 'fecha_inicio' || 'fecha_fin')) setValue(prop, filtros[`${prop}`]);
      }
    }
    combosEntregas(estadoEntregas.id, estadoEntregas.accion);
  }, [estadoEntregas.accion, estadoEntregas.id, setValue]);
  const combosEntregas = (registro: number, accion: string) => {
    GetEureka('/Entregas/combos').then((response) => {
      if (response.info) {
        var registro = response.content;
        setCombos({
          estados: registro.estados,
        });
      } else {
        console.log(response);
      }
    });
  };

  function changeValues(e: any) {
    if (e?.target?.name === 'fecha_fin' || e?.target?.name === 'fecha_inicio') {
      setValue(e.target.name, e.target.value);
      reset({ ...getValues(), [e.target.name]: e.target.value });
    } else {
      setValue(e.name, e.value);
      reset({ ...getValues(), [e.name]: e });
    }
  }

  function filterArticles(e: IUseForm) {
    for (let prop in e) {
      if (prop === 'fecha_inicio' && !e[`${prop}`].length) e[`${prop}`] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
      else if (prop === 'fecha_fin' && !e[`${prop}`].length) e[`${prop}`] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
      else if (prop === 'fecha_fin' && e[`${prop}`].length) e[`${prop}`] = fechaMoment(e[`${prop}`]);
      else if (prop === 'fecha_inicio' && e[`${prop}`].length) e[`${prop}`] = fechaMoment(e[`${prop}`]);
    }
    let setearObjeto = {
      ...e,
      fecha_desde: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_hasta: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      idtipoconector: 0,
      idtipoentrega: 0,
      nro_venta: '',
      producto: '',
    };
    localStorage.setItem('filtrosentregas', JSON.stringify(setearObjeto));
    setRecargaGridEntregas(moment().format('YYYY-MM-DD HH:mm:ss'));
    close();
  }
  const resetearFiltros = (e: React.MouseEvent<HTMLButtonElement>, origen: string) => {
    e.preventDefault();
    let reseteo: any = {
      idestado: { value: 0, label: 'Select...', name: 'idestado' },
      identrega: 0,
      codinterno: '',
      nroventa: '',
      producto: '',
      fecha_inicio: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_fin: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
    };
    localStorage.setItem('filtros' + origen, JSON.stringify(reseteo));
    for (let i in reset) {
      if (i === ('idestado' || 'identrega' || 'codinterno' || 'nroventa' || 'producto' || 'fecha_inicio' || 'fecha_fin')) setValue(i, reseteo[`${i}`]);
    }
    reset({
      idestado: { value: 0, label: 'Select...', name: 'idestado' },
      identrega: 0,
      codinterno: '',
      nroventa: '',
      producto: '',
      fecha_inicio: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_fin: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
    });
    setSelectKey1(selectKey1 + 1);
  };

  const devolverDefault = (valor: { value: number; label: string; name: string }, select: string) => {
    switch (select) {
      case 'estados':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idestado' };
      default:
        return valor ? valor : { value: 0, label: 'Select...', name: 'idestado' };
    }
  };

  const cerrar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    close();
  };

  return (
    <div className='d-flex flex-column justify-content-between height-100'>
      <form className='row height-100' onSubmit={handleSubmit(filterArticles)}>
        <div className='col-md-12'>
          <div className='col-12'>
            <div className='col-12'>
              <Form.Label htmlFor='codinterno'>Codinterno</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('codinterno')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='producto'>Producto</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('producto')} />
            </div>
          </div>
          <div className='col-12'>
            <Form.Label htmlFor='Entregas'>Origen</Form.Label>
            <Select
              key={selectKey1}
              defaultValue={devolverDefault(localFiltros?.idestado, 'estados')}
              options={[
                { value: 0, label: 'Select...', name: 'idestado' },
                ...combos.estados.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idestado',
                })),
              ]}
              onChange={changeValues}
            />
            <Form.Label htmlFor='Entregas'>Tipo de movimiento</Form.Label>
          </div>
          <div className='col-12'>
            <label id='fecha-desde-tablero'>Fecha inicio</label>
            <Form.Control type='date' id='fecha-desde-form' className='color-date-flexit' onChange={changeValues} name='fecha_inicio' max={fechaSelect(getValues().fecha_fin || moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'))} value={fechaSelect(getValues().fecha_inicio || '')} />
            <label id='fecha-desde-tablero'>Fecha fin</label>
            <Form.Control type='date' id='fecha-desde-form' className='color-date-flexit' onChange={changeValues} name='fecha_fin' min={fechaSelect(getValues().fecha_inicio || moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('DD/MM/YYYY HH:mm:ss'))} max={moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD')} value={fechaSelect(getValues().fecha_fin || '')} />
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-end botones-offcanvas'>
          <button className='btn background-roj btn-sm' onClick={cerrar}>
            Cerrar
          </button>
          <button className='btn background-ama btn-sm' onClick={(e) => resetearFiltros(e, 'entregas')}>
            Resetear
          </button>
          <button type='submit' className='btn background-ver btn-sm'>
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
};
export default FiltrosEntregas;
