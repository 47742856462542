import React, { useState, useEffect } from 'react';
import EurekaGeneral, { PutEureka, GetEureka, PostEureka } from '../../../estructura/EurekaGeneral/EurekaGeneral';
import { QRCodeCanvas } from 'qrcode.react';
import { Steps } from 'rsuite';
import moment from 'moment-timezone';

interface IFormEntregas {
  ListaChecks: IListaCheck[];
  SetRefresh: Function;
  ModalClose: Function;
}

interface IListaCheck {
  id: number;
  nroventa: string;
}

export const FormCanceladosEntregas: React.FC<IFormEntregas> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  function submit_cancelar_entregas() {
    let arrayPromesas = ListaChecks.map(async (value) => await PutEureka(`/Entregas/CancelarEntregas`, { entregas: ListaChecks }));
    Promise.all(arrayPromesas).then((response) => {
      if (response[0].info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroventa}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary btn-sm' onClick={submit_cancelar_entregas}>
          Cancelar entregas
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};

export const FormConfirmadosEntregas: React.FC<IFormEntregas> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  function submit_confirmar_entregas() {
    let arrayPromesas = ListaChecks.map(async (value) => await PutEureka(`/Entregas/Confirmar/${value.id}`, {}));
    Promise.all(arrayPromesas).then((response) => {
      if (response[0].info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroventa}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary btn-sm' onClick={submit_confirmar_entregas}>
          Confirmar entregas
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};
export const FormImprimirEntregas: React.FC<IFormEntregas> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  function submit_imprimir_entregas() {
    PostEureka('/Imprimir/Lista', ListaChecks).then((response) => {
      if (response.info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroventa}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary btn-sm' onClick={submit_imprimir_entregas}>
          Imprimir entregas
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};

interface IFormIdEntrega {
  identrega: number;
}

export const FormTrackingEntregas: React.FC<IFormIdEntrega> = ({ identrega }) => {
  const [tracking, setTracking] = useState({
    fecha_alta: '',
    fecha_pendiente: '',
    fecha_encamino: '',
    fecha_finalizado: '',
  });
  useEffect(() => {
    GetEureka(`/Entregas/TrackingEntregas/${identrega}`).then((response) => {
      if (response.info) {
        let respuesta = response.content;
        setTracking({
          fecha_alta: respuesta.fecha_alta,
          fecha_pendiente: respuesta.fecha_pendiente,
          fecha_encamino: respuesta.fecha_encamino,
          fecha_finalizado: respuesta.fecha_finalizado,
        });
      }
    });
  }, []);
  function devolverTexto(string: string): string {
    switch (string) {
      case 'fecha_alta':
        return 'Entrega ingresada';
      case 'fecha_pendiente':
        return 'Entrega preparada';
      case 'fecha_encamino':
        return 'Entrega en camino';
      case 'fecha_finalizado':
        return 'Entrega finalizada';
      default:
        return '';
    }
  }
  return (
    <div className='mt-5 mb-5 my-steps-rsuite'>
      <Steps current={Object.values(tracking).filter((value) => value !== null).length}>
        {Object.entries(tracking).map((value, key) => (
          <Steps.Item key={key} description={<BubbleComment fecha={value[1]}>{`${devolverTexto(value[0])} ${value[1] !== null ? moment(value[1]).format('DD/MM/YYYY') : 'Pendiente'}`}</BubbleComment>} />
        ))}
        <Steps.Item />
      </Steps>
    </div>
  );
};
interface IBubbleComment {
  children: React.ReactNode;
  fecha: string;
}
const BubbleComment: React.FC<IBubbleComment> = ({ children, fecha }) => {
  return (
    <div style={{ position: 'relative', padding: '10px', borderRadius: '8px', border: `1px solid ${fecha ? '#3498ff' : '#aaa'}`, color: `${fecha ? '#3498ff' : '#aaa'}` }}>
      <div style={{ position: 'absolute', top: '-10px', left: '50%', marginLeft: '-5px', borderTop: `10px solid ${fecha ? '#3498ff' : '#aaa'}`, borderRight: '10px solid transparent', borderLeft: '10px solid transparent' }}></div>
      {children}
    </div>
  );
};
export function cancelarEntregaUnitaria(e: any, setChequeo: Function, ModalShow: any) {
  const id = Number(e.target.dataset.value);
  const nroventa = Number(e.target.dataset.nroventa);
  const bolsa = { id: id, nroventa: nroventa };
  setChequeo([bolsa]);
  ModalShow(1);
}
export const FormQrEntregas: React.FC<IFormIdEntrega> = ({ identrega }) => {
  const [content, setContent] = useState(
    <div className='alert alert-danger' role='alert'>
      Sin registros para mostrar
    </div>
  );
  useEffect(() => {
    setContent(
      <div className='row'>
        <div className='mt-5 mb-4 text-center'>
          <QRCodeCanvas size={300} value={identrega.toString()} />
        </div>
      </div>
    );
  }, [identrega]);
  return <>{content}</>;
};
export default function FormGenericos() {
  return <></>;
}
