import React, { useState } from 'react';
import { Badge } from 'react-bootstrap';
import EurekaGeneral from '../../estructura/EurekaGeneral/EurekaGeneral';
import EurekaModals from '../../estructura/EurekaModals/EurekaModals';
import { FormCanceladosOrdenes, FormConfirmadosOrdenes } from './Genericos/FormGenericos';
import $ from 'jquery';

interface IEurekaOrdenesPendientes {
  encaminos: IEncamino[];
  SetRefresh: Function;
  direction: boolean;
  hidden?: boolean;
}

interface IEncamino {
  estado: string;
  nro_venta: string;
  nroorden: string;
  chofer: string;
  sucursal: string;
  total: number;
  idorden: number;
  idestado: number;
}

const EurekaOrdenesEnCamino: React.FC<IEurekaOrdenesPendientes> = ({ encaminos = [], direction = true, SetRefresh }) => {
  const [chequeosEncamino, setChequeosEncamino] = useState([]);
  const [ordenActivo, setOrdenActivo] = useState({ id: 0, nroorden: '' });
  const [modalTitulo, setModalTitulo] = useState('Cancelar Ordenes');
  const [modalEncamino, setmmodalEncamino] = useState({ show: false, tipo: 0 });
  const FnSetOrdenActivo = (id: number, nroorden: string) => setOrdenActivo({ id: id, nroorden: nroorden });
  const ModalClose = () => setmmodalEncamino({ show: false, tipo: 0 });
  const ModalShow = (tipo: number) => setmmodalEncamino({ show: true, tipo: tipo });
  function checkallencamino() {
    if ($('.all_chk_encamino').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_ec_"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_ec_"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function btn_confirmar_entrega() {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>('[class^="chk_ec_"]:checked').forEach((input) => {
      const id = Number(input.value);
      const nroorden = Number(input.dataset.nroorden);
      const bolsa_ = { id: id, nroorden: nroorden };
      bolsa.push(bolsa_);
    });
    setModalTitulo('Confirmar Ordenes');
    setChequeosEncamino(bolsa);
    ModalShow(2);
  }
  function btn_cancelar_entrega() {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>('[class^="chk_ec_"]:checked').forEach((input) => {
      const id = Number(input.value);
      const nroorden = Number(input.dataset.nroorden);
      const bolsa_ = { id: id, nroorden: nroorden };
      bolsa.push(bolsa_);
    });
    setModalTitulo('Cancelar Ordenes');
    setChequeosEncamino(bolsa);
    ModalShow(1);
  }
  return (
    <div className={direction ? 'col-md-12 mt-4' : 'col-md-4'}>
      <>
        <EurekaModals manejador={modalEncamino} modalTitulo={modalTitulo} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosEncamino)} handleClose={ModalClose}>
          {modalEncamino.tipo === 1 ? (
            <FormCanceladosOrdenes ListaChecks={chequeosEncamino} SetRefresh={SetRefresh} ModalClose={ModalClose} />
          ) : modalEncamino.tipo === 2 ? (
            <FormConfirmadosOrdenes ListaChecks={chequeosEncamino} SetRefresh={SetRefresh} ModalClose={ModalClose} />
          ) : (
            <div className='alert alert-danger' role='alert'>
              No hay entregas seleccionadas
            </div>
          )}
        </EurekaModals>
        <div>
          <div className='card col_entregas_encamino'>
            <div className='card-header eureka-bg-warning'>
              <div className='row'>
                <div className='col-10 text-warning'>
                  <input className='all_chk_encamino' onClick={checkallencamino} type='checkbox' /> En camino <Badge bg='warning'>{encaminos.length}</Badge>
                </div>
                <div className='col-2 text-end'>
                  <div className='dropdow'>
                    <button className='btn btn-warning btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                      +
                    </button>
                    <ul className='dropdown-menu'>
                      <li>
                        <button className='btn btn-xs dropdown-item' onClick={btn_cancelar_entrega}>
                          Cancelar orden
                        </button>
                      </li>
                      <li>
                        <button className='btn btn-xs dropdown-item' onClick={btn_confirmar_entrega}>
                          Confirmar orden
                        </button>
                      </li>
                    </ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className={`accordion ${direction ? 'row' : ''}`} id='accordionEncamino'>
                {encaminos.map((encamino, key) => (
                  <div className={`accordion-item ${direction ? 'col-md-4' : ''}`} key={key}>
                    <div className='accordion-header eureka-bg-warning accordion-encamino'>
                      <nav className='navbar bg-body-tertiary'>
                        <div className='container-fluid'>
                          <div className='form-check'>
                            <input className={'chk_ec_' + key + ' form-check-input me-1'} data-nroorden={encamino.nroorden} type='checkbox' value={encamino.idorden} id={'chk_enc_' + key} />
                            <label className='text-warning form-check-label f-13' data-bs-toggle='collapse' data-bs-target={'#collapse_enc_' + key} aria-expanded='false' aria-controls={'collapse_enc_' + key}>
                              #{encamino.nroorden} - {encamino.estado}
                            </label>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div id={'collapse_enc_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionEncamino'>
                      <div className='text-dark accordion-body'>
                        {/* <small>Origen: {encamino.nro_venta}</small><br /> */}
                        <small>Chofer: {encamino.chofer}</small>
                        <br />
                        <small>Sucursal: {encamino.sucursal}</small>
                        <br />
                        <small>Cantidad: {encamino.total}</small>
                        <br />
                        {/* <Button size="sm pt-0 pb-0 w-100" variant="primary">Modificar</Button> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EurekaOrdenesEnCamino;
