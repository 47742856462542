import { Form } from 'react-bootstrap';
import { UseFormRegister, UseFormGetValues } from 'react-hook-form';
interface IUseForm {
  fecha_desde: string;
  fecha_hasta: string;
  idtipoconector: number;
  idtipoentrega: number;
  idestado: number;
  nro_venta: string;
  producto: string;
}

interface ICombos {
  id: number;
  descripcion: string;
  orden: number;
}

interface IFiltroTableroEntregas {
  props: {
    register: UseFormRegister<IUseForm>;
    SetRefresh: Function;
    getValues: UseFormGetValues<IUseForm>;
    offCanvasFiltrosClose: Function;
    estados: ICombos[];
    tipoConector: ICombos[];
    tipoEntregas: ICombos[];
  };
}

const FiltroTableroEntregas: React.FC<IFiltroTableroEntregas> = ({ props }) => {
  function submitFiltros() {
    let filtros = localStorage.getItem('filtrosentregas')?.length ? JSON.parse(localStorage.getItem('filtrosentregas') ?? '') : null;
    props.SetRefresh();
    props.offCanvasFiltrosClose();
    let values = props.getValues();
    if (filtros) {
      let newValues = {
        ...filtros,
        ...values,
        idtipoconector: values.idtipoconector,
        idestado: values.idestado,
      };
      localStorage.setItem('filtrosentregas', JSON.stringify(newValues));
    } else {
      let newValues = {
        ...values,
        idestado: values.idestado,
        idtipoconector: values.idtipoconector,
        identrega: 0,
        codinterno: '',
        nroventa: '',
        producto: '',
        fecha_inicio: '',
        fecha_fin: '',
      };
      localStorage.setItem('filtrosentregas', JSON.stringify(newValues));
    }
  }

  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_desde'>Fecha desde</Form.Label>
            <Form.Control type='date' id='fecha_desde' {...props.register('fecha_desde')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_hasta'>Fecha hasta</Form.Label>
            <Form.Control type='date' id='fecha_hasta' {...props.register('fecha_hasta')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idtipoconector'>Tipo conector</Form.Label>
            <Form.Select id='idtipoconector' {...props.register('idtipoconector')}>
              <option value='0'>Seleccionar</option>
              {props.tipoConector?.map((value, key) => (
                <option key={key} value={value.orden}>
                  {value.descripcion}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idtipoentrega'>Tipo entrega</Form.Label>
            <Form.Select id='idtipoentrega' {...props.register('idtipoentrega')}>
              <option value='0'>Seleccionar</option>
              {props.tipoEntregas?.map((value, key) => (
                <option key={key} value={value.orden}>
                  {value.descripcion}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idestado'>Estado</Form.Label>
            <Form.Select id='idestado' {...props.register('idestado')}>
              <option value='0'>Seleccionar</option>
              {props.estados?.map((value, key) => (
                <option key={key} value={value.orden}>
                  {value.descripcion}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='nro_venta'>Nro venta</Form.Label>
            <Form.Control type='text' id='nro_venta' placeholder='Igual a ...' {...props.register('nro_venta')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='producto'>Producto</Form.Label>
            <Form.Control type='text' id='producto' placeholder='Contiene ...' {...props.register('producto')} />
          </div>
          <div className='form-group mt-4'>
            <button onClick={submitFiltros} className='btn btn-primary w-100 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroTableroEntregas;
