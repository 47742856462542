import React from 'react';
import { IconField } from '../fields';
import { RevenueChart } from '../charts';
import { Box, Heading, Text, Icon } from '../elements';

interface IRevenueCard {
  onChange: React.ChangeEventHandler<HTMLSelectElement>;
  title: string;
  field: { icon: string; option: { dias: string; value: number }[] };
  report?: any[];
  chart: { consignacion: number; entregas: number; month: string; ordenes: number };
}

const RevenueCard: React.FC<IRevenueCard> = ({ onChange, title, field, report, chart }) => {
  console.log(title, field, report, chart);
  return (
    <Box className='mc-card'>
      <Box className='mc-revenue-card-header'>
        <Heading as='h5' className='mc-card-title'>
          {title}
        </Heading>
        <IconField icon={field.icon} option={field.option} onChangeSelect={onChange} classes='w-sm h-sm' />
      </Box>
      <Box className='mc-revenue-card-group'>
        {report?.map((item, index) => (
          <Box key={index} className='mc-revenue-card-report'>
            <Icon className={`material-icons ${item.variant}`}>{item.icon}</Icon>
            <Heading>
              <Text as='span'>{item.label}</Text> {item.number}
            </Heading>
          </Box>
        ))}
      </Box>
      <RevenueChart chart={chart} />
    </Box>
  );
};

export default RevenueCard;
