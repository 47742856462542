import moment from 'moment-timezone';
import 'select2';

export function fechaSelect(fecha = 'dd/mm/aaaa') {
  return fecha.split(' ')[0];
}

export function fechaMoment(fecha: string) {
  const fechaMomento: moment.Moment = moment(fecha, 'YYYY-MM-DD');
  const horaActual = moment().tz('America/Argentina/Buenos_Aires').format('HH:mm:ss');
  fechaMomento.set({ hour: parseInt(horaActual.split(':')[0]), minute: parseInt(horaActual.split(':')[1]), second: parseInt(horaActual.split(':')[2]) });
  return fechaMomento.format('YYYY-MM-DD HH:mm:ss');
}

export const devolverFormatoDefaultById = (name: string) => {
  if (name === 'ccorriente')
    return {
      tipo_movimiento: '',
      tipo: '',
      monto: '',
      fecha_alta: '',
    };
  else if (name === 'ventaconsignacion')
    return {
      articulo: '',
      cantidad: '',
      monto: '',
      fecha_alta: '',
    };
  else if (name === 'entregas')
    return {
      codinterno: '',
      nro_venta: '',
      direccion: '',
      fecha_alta: '',
      productos: [],
    };
  else if (name === 'ordenes')
    return {
      nroorden: '',
      fecha_alta: '',
      productos: [],
    };
  else
    return {
      articulo: '',
      stock_minimo: '',
      stock_actual: '',
      stock_disponible: '',
      stock_reservado: '',
      precio_costo: '',
      precio_venta: '',
      sku: '',
    };
};

export const devolverClassColumn = (key: number) => {
  if (key === 0 || key === 2) return 'width-opciones';
  else return '';
};

export const devolverValor = (valor: string) => {
  switch (valor) {
    case 'Egreso':
      return "<span className='material-symbols-outlined'>arrow_downward</span>";
    case 'Ingreso':
      return "<span className='material-symbols-outlined'>arrow_upward</span>";
    default:
      return valor;
  }
};

export const defaultValue = () => {
  let obj = Array.from(document.getElementsByClassName('js-example-basic-single'));
  const input: any = document.getElementById('inputProduc');
  obj?.map((value: any) => (value[0].value = '0'));
  if (input) input.value = '';
};

export const cambioFormatoFechaEnTables = (table: any[], active: string) => {
  return table?.map((value: any) => {
    if (active !== 'articulos') return { ...value, fecha_alta: cambioFormatoFecha(value.fecha_alta) };
    else return value;
  });
};

export const cambioFormatoFecha = (fecha: string, name?: string): string => {
  if (name === 'sku') {
    const newFecha = fecha?.split('T')[0];
    const newFecha2 = newFecha?.split('-').reverse().join('-');
    return newFecha2;
  } else {
    const newFecha = fecha?.split('T')[0];
    const newFecha2 = newFecha?.split('-').reverse().join('/');
    return newFecha2;
  }
};

export const tipoFormato = (formato: string) => {
  switch (formato) {
    case 'ventaconsignacion':
      return {
        identrega: 0,
        idproducto: 0,
        fecha_fin: moment().tz('America/Argentina/Buenos_Aires').add(1, 'days').format(),
        fecha_inicio: moment().subtract(15, 'days').tz('America/Argentina/Buenos_Aires').format(),
      };
    case 'auditoria':
      return {
        idusuario: 0,
        tipo: '',
        modulo: '',
        ip: '',
        mensaje: '',
        fecha_fin: moment().tz('America/Argentina/Buenos_Aires').add(1, 'days').format(),
        fecha_inicio: moment().subtract(15, 'days').tz('America/Argentina/Buenos_Aires').format(),
      };
    case 'articulos':
      return {
        idarticulo: 0,
        articulo: '',
        idtipo: 0,
        idmarca: 0,
        idmodelo: 0,
        idproveedor: 0,
        fecha_fin: moment().tz('America/Argentina/Buenos_Aires').add(1, 'days').format(),
        fecha_inicio: moment().subtract(15, 'days').tz('America/Argentina/Buenos_Aires').format(),
      };
    case 'ordenes':
      return {
        idorden: 0,
        idchofer: 0,
        idestado: 0,
        nroorden: '',
        producto: '',
        fecha_fin: moment().tz('America/Argentina/Buenos_Aires').add(1, 'days').format(),
        fecha_inicio: moment().subtract(15, 'days').tz('America/Argentina/Buenos_Aires').format(),
      };
    case 'ccorriente':
      return {
        idccoriente: 0,
        idorigen: 0,
        idtipomovimiento: 0,
        detallemovimiento: '',
        idtipo: 0,
        fecha_fin: moment().tz('America/Argentina/Buenos_Aires').add(1, 'days').format(),
        fecha_inicio: moment().subtract(15, 'days').tz('America/Argentina/Buenos_Aires').format(),
      };
    default:
      return {
        identrega: 0,
        idestado: 0,
        codinterno: '',
        nroventa: '',
        producto: '',
        fecha_fin: moment().tz('America/Argentina/Buenos_Aires').add(1, 'days').format(),
        fecha_inicio: moment().subtract(15, 'days').tz('America/Argentina/Buenos_Aires').format(),
      };
  }
};

export const mensajeError = (error: any) => {
  return error;
};

export const validarLongitud = (obj: any) => {
  const validacion = Object.values(obj || {})?.map((values: any) => {
    if (!values.length) return false;
    else return true;
  });
  if (validacion.includes(false) || !Object.values(obj).length || validacion.length !== 2) return { msg: 'Faltan completar campos', value: false };
  else return { value: true };
};

export const redireccionLogin = (navigate: any) => {
  if (!localStorage.getItem('token')) navigate('/');
};

export const redireccionInicio = (navigate: any) => {
  if (localStorage.getItem('token')?.length) {
    navigate('/inicio');
  }
};

export const recorte = (texto: string): string => {
  if (texto.length > 20) return texto.slice(0, 20) + '...';
  else return texto;
};

export function recortarFecha(fecha: string): string {
  return fecha?.split('T')[0];
}

export function formatoFecha(fecha: string): string {
  return moment(fecha).tz('America/Argentina/Buenos_Aires').format();
}

export function primeraMayuscula(palabra: string): string {
  let mayuscula = palabra?.charAt(0)?.toUpperCase();
  let resto = palabra?.slice(1)?.split('_').join(' ');
  return mayuscula?.concat(resto);
}

export function singular(text: string): string {
  if (text === 'proveedores') return 'proveedor';
  else if (text.at(-1) === 's') return text.slice(0, -1);
  else return text;
}

export function cambioPrecioDecimales(value: string): string {
  return Number.parseFloat(value)?.toFixed(2);
}
