import React from 'react';
import { DevicesChart } from '../charts';
import { Box, Heading } from '../elements';

interface IDevicesCard {
  title: string;
  chart?: IDiario[];
}

interface IDiario {
  fecha: string;
  cantidad: number;
}

const DevicesCard: React.FC<IDevicesCard> = ({ title, chart }) => {
  return (
    <Box className='mc-card'>
      <Box className='mc-devices-card-head'>
        <Heading as='h5'>{title}</Heading>
      </Box>
      <DevicesChart chart={chart} />
    </Box>
  );
};

export default DevicesCard;
