import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment-timezone';

interface IFormCcorriente {
  estadoCcorriente: { show: boolean; id: number; accion: string };
  formDisabled: boolean;
  close: Function;
}

interface IUseForm {
  tipo_movimiento: string;
  tipo: string;
  monto: string;
  fecha_alta: string;
}

const FormCcorriente: React.FC<IFormCcorriente> = ({ estadoCcorriente, formDisabled, close }) => {
  const { register, setValue, reset } = useForm<IUseForm>({
    defaultValues: {
      tipo_movimiento: '',
      tipo: '',
      monto: '',
      fecha_alta: '',
    },
  });
  const articulobyid = useCallback(
    (registro: number, accion: string) => {
      GetEureka('/Ccorriente/' + registro).then((response) => {
        if (response.info) {
          var registro = response.content[0];
          setValue('tipo_movimiento', registro.tipo_movimiento);
          setValue('tipo', registro.tipo);
          setValue('monto', registro.monto);
          setValue('fecha_alta', moment(registro.fecha_alta).format('DD/MM/YYYY'));
          reset({
            tipo_movimiento: registro.tipo_movimiento,
            tipo: registro.tipo,
            monto: registro.monto,
            fecha_alta: moment(registro.fecha_alta).format('DD/MM/YYYY'),
          });
        } else {
          console.log(response);
        }
      });
    },
    [reset, setValue]
  );
  useEffect(() => {
    if (estadoCcorriente.show) articulobyid(estadoCcorriente.id, estadoCcorriente.accion);
  }, [articulobyid, estadoCcorriente.id, estadoCcorriente.accion]);
  function cerrar(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    close();
  }
  return (
    <>
      <form className='row'>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='chofer'>Tipo de movimiento</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('tipo_movimiento')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='nroorden'>Tipo</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('tipo')} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='estado'>Monto</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('monto')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='fecha_alta'>Fecha de alta</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('fecha_alta')} />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-sm background-roj' onClick={cerrar}>
            Cerrar
          </button>
        </div>
      </form>
    </>
  );
};
export default FormCcorriente;
