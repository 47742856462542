import React, { useState } from 'react';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faLocationArrow } from '@fortawesome/free-solid-svg-icons';
import { Badge } from 'react-bootstrap';
import moment from 'moment-timezone';
import EurekaModals from '../../estructura/EurekaModals/EurekaModals';
import EurekaGeneral from '../../estructura/EurekaGeneral/EurekaGeneral';
import { FormCanceladosEntregas } from './Genericos/FormGenericos';
const { REACT_APP_URL_BLUEHOST } = process.env;

interface IEurekaTableroFinalizadas {
  finalizados: IFinalizado[];
  underline: React.MouseEventHandler<HTMLLabelElement>;
  noUnderline: React.MouseEventHandler<HTMLLabelElement>;
  SetRefresh: Function;
  direction: boolean;
  visible: boolean;
}

interface IFinalizado {
  nro_venta: string;
  identrega: number;
  fecha: string;
  codinterno: string;
  tipoconector: number;
  productos: { cantidad: number; articulo: string }[];
  direccion: string;
  telefono: number;
  comentario: string;
  fecha_alta: string;
  observaciones: string;
}

const EurekaTableroFinalizadas: React.FC<IEurekaTableroFinalizadas> = ({ finalizados = [], underline, noUnderline, SetRefresh, direction, visible }) => {
  const [chequeosFinalizados, setchequeosFinalizados] = useState([]);
  const [modalFinalizado, setmmodalFinalizado] = useState({ show: false, tipo: 0 });
  const [modalTitulo] = useState('Cancelar Entregas');
  const ModalClose = () => setmmodalFinalizado({ show: false, tipo: 0 });
  return (
    <div hidden={visible} className={direction ? 'col-md-12 mt-2' : 'col-md-4'}>
      <EurekaModals manejador={modalFinalizado} modalTitulo={modalTitulo} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosFinalizados) as 'lg' | 'sm' | 'xl' | undefined} handleClose={ModalClose}>
        {modalFinalizado.tipo === 1 ? (
          <FormCanceladosEntregas ListaChecks={chequeosFinalizados} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div>
        <div className='card col_entregas_finalizadas'>
          <div className='card-header eureka-bg-success'>
            <div className='row'>
              <div className='col-6 text-success'>
                Finalizados <Badge bg='success'>{finalizados.length}</Badge>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direction ? 'row' : ''}`} id='accordionFinalizados'>
              {finalizados.map((finalizado, key) => (
                <div className={`accordion-item ${direction ? 'col-md-4' : ''}`} key={key}>
                  <div className='accordion-header eureka-bg-success accordion-finalizados'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <label className='text-success form-check-label f-13' data-bs-toggle='collapse' data-bs-target={'#collapse_fin_' + key} aria-expanded='false' aria-controls={'collapse_fin_' + key} onMouseEnter={underline} onMouseLeave={noUnderline}>
                          #{finalizado.codinterno} - {moment(finalizado.fecha_alta).format('DD/MM/YYYY')}
                        </label>
                        <div className='dropdown' key={key} id={`menu-fin-${key}`}>
                          <button className='btn btn-success btn-sm dropdown-toggle button-conector' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {finalizado.tipoconector}
                          </button>
                          <ul className='dropdown-menu'>
                            <li>
                              <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + finalizado.nro_venta} className='dropdown-item' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={faLocationArrow} /> Seguimiento
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_fin_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionFinalizados'>
                    <div className='text-dark accordion-body'>
                      {finalizado.productos.map((value, key) => (
                        <small key={key}>{`(${value.cantidad}) ${value.articulo}`}</small>
                      ))}
                      <hr></hr>
                      <small>
                        Nro Venta:{' '}
                        <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + finalizado.nro_venta} className='btn btn-link' target='_blank' rel='noreferrer'>
                          <small>{finalizado.nro_venta}</small>
                        </a>
                      </small>
                      <br />
                      <small>Dirección: {finalizado.direccion}</small>
                      <br />
                      <small>
                        Recibe: {finalizado.direccion} | fono: {finalizado.telefono}
                      </small>
                      <br />
                      <small>Comentario: {finalizado.comentario}</small>
                      <br />
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default EurekaTableroFinalizadas;
