import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import FormArticulos from './Form-Articulos';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltrosArticulos from './Filtros-Articulos';
import StockArticulos from './Stock-Articulos';

function Articulos() {
  const [modalArticulos, setmodalArticulos] = useState({ show: false, id: 0, accion: 'a' });
  const ModalClose = () => setmodalArticulos({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalArticulos({ show: true, id: id, accion: accion });

  const [offcanvasArticulos, setoffcanvasArticulos] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasClose = () => setoffcanvasArticulos({ show: false, id: 0, accion: 'a' });
  const offCanvasShow = (id: number, accion: string) => setoffcanvasArticulos({ show: true, id: id, accion: accion });
  const [recargaGridStock, setRecargaGridStock] = useState('');

  const [offcanvasFiltrosArticulos, setoffcanvasFiltrosArticulos] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosClose = () => setoffcanvasFiltrosArticulos({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosArticulos({ show: true, id: id, accion: accion });

  const [recargaGridArticulos, setRecargaGridArticulos] = useState('');
  const [formDisabled, setformDisabled] = useState(false);
  const [disabledUpload, setDiisabledUpload] = useState(false);

  const manejo_acciones = (origen: string, registro: number, accion: string) => {
    switch (accion) {
      case 'c':
        setDiisabledUpload(true);
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'i':
        offCanvasShow(registro, accion);
        break;
      case 'a':
        offCanvasFiltrosShow(registro, accion);
        break;
      default:
        return;
    }
  };
  return (
    <>
      <PageLayout label='Adm. Articulos'>
        <Card>
          <Card.Body>
            {Grid(manejo_acciones, 'articulos', recargaGridArticulos, setRecargaGridArticulos)}
            <EurekaModals id='modal_articulos' manejador={modalArticulos} modalTitulo='Consultar articulo' sizeModal='xl' handleClose={ModalClose}>
              <FormArticulos disabledUpload={disabledUpload} estadoArticulos={modalArticulos} formDisabled={formDisabled} close={ModalClose}></FormArticulos>
            </EurekaModals>
            <EurekaOffcanvas id='offcanvas_filtrosarticulos' manejador={offcanvasFiltrosArticulos} offcanvasTitulo='Filtros' handleClose={offCanvasFiltrosClose} posicion='start' size='w-20'>
              <FiltrosArticulos estadoArticulos={offcanvasArticulos} close={offCanvasFiltrosClose} setRecargaGridArticulos={setRecargaGridArticulos}></FiltrosArticulos>
            </EurekaOffcanvas>
            <EurekaOffcanvas id='offcanvas_articulos' manejador={offcanvasArticulos} offcanvasTitulo='Inventario' handleClose={offCanvasClose} posicion='end' size='w-35'>
              <StockArticulos recargaGridStock={recargaGridStock} setRecargaGridStock={setRecargaGridStock} offcanvasArticulos={offcanvasArticulos}></StockArticulos>
            </EurekaOffcanvas>
          </Card.Body>
        </Card>
      </PageLayout>
    </>
  );
}
export default Articulos;
