import React, { useState, useEffect, useCallback } from 'react';
import { WidgetDropdown, ProfileDropdown } from '../components/header';
import useGetComplexObject from '../hooks/useGetComplexObject';
import { Button, Section, Box } from '../components/elements';
import { DrawerContext } from '../context/Drawer';
import { ThemeContext } from '../context/Themes';
import { FlexitContext } from '../context/Flexit';
import { Logo } from '../components';
import data from '../data/master/header.json';
import src from '../images/logo/logo/72/Logo_colores_72_original copia 3.png';
import { BotonesHeader } from '../components';
import { useNavigate } from 'react-router-dom';

interface IHeader {
  label: string;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

interface ThemeContextType {
  theme: string;
  toggleTheme: () => void;
}

interface IDrawerContextType {
  drawer: boolean;
  toggleDrawer: () => void;
}

const Header: React.FC<IHeader> = ({ label }) => {
  const navigate = useNavigate();
  const { drawer, toggleDrawer } = useGetComplexObject<IDrawerContextType>(DrawerContext);
  const { theme, toggleTheme } = useGetComplexObject<ThemeContextType>(ThemeContext);
  const { datos, tokenDecifrado, setearDatos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [scroll] = useState('sticky');
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState('');

  const traerUsuario = useCallback(
    async (mandar: string) => {
      let token = await tokenDecifrado(mandar);
      setearDatos(token.data);
    },
    [setearDatos, tokenDecifrado]
  );

  function cerrarSesion() {
    localStorage.removeItem('token');
    navigate('/');
  }
  useEffect(() => {
    const defaultImage = 'https://cdn-icons-png.flaticon.com/512/3033/3033143.png';
    const userImage = datos?.usuario?.imagen;
    const imageToLoad = userImage === '../assets/images/logo.jpg' ? defaultImage : userImage;
    if (!Object.keys(datos).length && localStorage.getItem('token')) traerUsuario(localStorage.getItem('token') ?? '');
    if (imageToLoad) {
      const img = new Image();
      img.src = imageToLoad;
      img.onload = () => {
        setImageSrc(imageToLoad);
        setImageLoaded(true);
      };
    }
  }, [datos, traerUsuario]);

  return (
    <Section as='header' className={`mc-header ${scroll}`}>
      <Logo src={src} alt={data?.logo.alt} id={'image-flexit'} name={''} href={'/dashboard'} />
      <Box className='mc-header-group'>
        <Box className='mc-header-left'>
          <Button icon={drawer ? 'menu_open' : 'menu'} className='mc-header-icon toggle col-2' onClick={toggleDrawer} />
        </Box>
        <Box className='w-100 d-sm-none d-md-block d-none d-sm-block'>
          <BotonesHeader label={label} />
        </Box>
        <Box className='mc-header-right'>
          <Button icon={theme} onClick={toggleTheme} className={`mc-header-icon ${data.theme.addClass}`} />
          <WidgetDropdown icon={data.notify.icon} title={data.notify.title} badge={data.notify.badge} addClass={data.notify.addClass} dropdown={data.notify.dropdown} />
          {!imageLoaded ? (
            <div className='spinner-border' role='status'>
              <span className='visually-hidden'>Loading...</span>
            </div>
          ) : (
            <ProfileDropdown name={`${datos?.usuario?.nombres} ${datos?.usuario?.apellidos}`} image={imageLoaded ? imageSrc : datos?.usuario?.imagen} username={`${datos?.usuario?.nombres} ${datos?.usuario?.apellidos}`} dropdown={data.profile.dropdown} onClick={cerrarSesion} />
          )}
        </Box>
      </Box>
    </Section>
  );
};

export default Header;
