import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import FormCcorriente from './Form-Ccorriente';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltrosCcorriente from './Filtros-Ccorriente';

function Ccorriente() {
  const [modalCcorriente, setmodalCcorriente] = useState({ show: false, id: 0, accion: 'a' });
  const ModalClose = () => setmodalCcorriente({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalCcorriente({ show: true, id: id, accion: accion });

  const [offcanvasCcorriente, setoffcanvasCcorriente] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasShow = (id: number, accion: string) => setoffcanvasCcorriente({ show: true, id: id, accion: accion });

  const [offcanvasFiltrosCcorriente, setoffcanvasFiltrosCcorriente] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosClose = () => setoffcanvasFiltrosCcorriente({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosCcorriente({ show: true, id: id, accion: accion });

  const [recargaGridCcorriente, setRecargaGridCcorriente] = useState('');
  const [formDisabled, setformDisabled] = useState(false);

  const manejo_acciones = (origen: string, registro: number, accion: string) => {
    switch (accion) {
      case 'c':
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'i':
        offCanvasShow(registro, accion);
        break;
      case 'a':
        offCanvasFiltrosShow(registro, accion);
        break;
      default:
        return;
    }
  };
  return (
    <>
      <PageLayout label='Adm. Cuenta corriente'>
        <Card>
          <Card.Body>
            {Grid(manejo_acciones, 'ccorriente', recargaGridCcorriente, setRecargaGridCcorriente)}
            <EurekaModals id='modal_Ccorriente' manejador={modalCcorriente} modalTitulo='Consultar cuenta corriente' sizeModal='xl' handleClose={ModalClose}>
              <FormCcorriente estadoCcorriente={modalCcorriente} formDisabled={formDisabled} close={ModalClose}></FormCcorriente>
            </EurekaModals>
            <EurekaOffcanvas id='offcanvas_filtrosccorriente' manejador={offcanvasFiltrosCcorriente} offcanvasTitulo='Filtros' handleClose={offCanvasFiltrosClose} posicion='start' size='w-20'>
              <FiltrosCcorriente estadoCcorriente={offcanvasCcorriente} close={offCanvasFiltrosClose} setRecargaGridCcorriente={setRecargaGridCcorriente}></FiltrosCcorriente>
            </EurekaOffcanvas>
          </Card.Body>
        </Card>
      </PageLayout>
    </>
  );
}
export default Ccorriente;
