import { useCallback, useEffect, useRef } from 'react';
import { Table } from 'react-bootstrap';
import 'datatables.net-bs5/css/dataTables.bootstrap5.min.css';
import moment from 'moment-timezone';
const { REACT_APP_API_URL_2 } = process.env;
const $ = require('jquery');
$.DataTable = require('datatables.net');
require('datatables.net-responsive');
require('datatables.net-colreorder/js/dataTables.colReorder');
require('datatables.net-bs5/js/dataTables.bootstrap5');
require('datatables.net-buttons-dt/js/buttons.dataTables.mjs');
require('datatables.net-buttons/js/buttons.print.min');
require('datatables.net-buttons/js/buttons.html5.min');

interface IFiltro {
  id?: number;
  idarticulo?: number;
}

const Grid = (manejo_acciones: Function | null, origen: string, gridCarga: string, recargaGrid: Function, filtro?: IFiltro): JSX.Element => {
  const groupColumn = 2;
  const getUrlOrigen = useCallback((origen: string) => {
    let token = localStorage.getItem('token') ?? '';
    let url = '';
    switch (origen) {
      case 'articulos':
        url = REACT_APP_API_URL_2 + '/Articulos?token=' + token;
        break;
      case 'ordenes':
        url = REACT_APP_API_URL_2 + '/Ordenes?token=' + token;
        break;
      case 'ccorriente':
        url = REACT_APP_API_URL_2 + '/Ccorriente?token=' + token;
        break;
      case 'entregas':
        url = REACT_APP_API_URL_2 + '/Entregas?token=' + token;
        break;
      case 'ventaconsignacion':
        url = REACT_APP_API_URL_2 + '/Ventaconsignacion?token=' + token;
        break;
      case 'stock':
        url = REACT_APP_API_URL_2 + '/Stock/' + filtro?.id + '?token=' + token;
        break;
      default:
        url = '';
        break;
    }
    return url;
  }, []);
  const getTipoTable = (origen: string) => {
    switch (origen) {
      default:
        return 'table-bordered tabla-generica';
    }
  };
  const getButtonOpciones = (data: string, disabled: boolean, origen: string, full: any) => {
    var btn = '';
    var btnConsulta = "<li><a key='" + data + "' accion='c' class='dropdown-item' >Consultar</a></li>";
    var btnConfirmar = "<li><a key='" + data + "' class='dropdown-item' accion='cfm' >Confirmar</a></li>";
    var btnInventario = "<li><a key='" + data + "' accion='i' class='dropdown-item' >Ver inventario</a></li>";
    var btnConfirmarOrden = "<li><a key='" + data + "' class='dropdown-item' accion='co' >Confirmar</a></li>";
    var btnRechazarOrden = "<li><a key='" + data + "' class='dropdown-item' accion='ro' >Rechazar</a></li>";
    switch (origen) {
      case 'articulos':
        btn = btnConsulta + btnInventario;
        break;
      case 'ordenes':
        full.estado === 'En camino' ? (btn = btnConsulta + btnConfirmarOrden + btnRechazarOrden) : (btn = btnConsulta);
        break;
      case 'ccorriente':
        btn = btnConsulta;
        break;
      case 'entregas':
        full.estado === 'Entrega Finalizada' ? (btn = btnConsulta) : (btn = btnConsulta + btnConfirmar);
        break;
      case 'ventaconsignacion':
        btn = btnConsulta;
        break;
      default:
        btn = '';
    }
    return "<div class='dropdown'>" + "<button title='" + data + "' class='btn btn-default btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>" + "<svg xmlns='http://www.w3.org/2000/svg' width='16' height='16' fill='currentColor' class='bi bi-gear' viewBox='0 0 16 16'>" + "<path d='M8 4.754a3.246 3.246 0 1 0 0 6.492 3.246 3.246 0 0 0 0-6.492zM5.754 8a2.246 2.246 0 1 1 4.492 0 2.246 2.246 0 0 1-4.492 0z'/>" + "<path d='M9.796 1.343c-.527-1.79-3.065-1.79-3.592 0l-.094.319a.873.873 0 0 1-1.255.52l-.292-.16c-1.64-.892-3.433.902-2.54 2.541l.159.292a.873.873 0 0 1-.52 1.255l-.319.094c-1.79.527-1.79 3.065 0 3.592l.319.094a.873.873 0 0 1 .52 1.255l-.16.292c-.892 1.64.901 3.434 2.541 2.54l.292-.159a.873.873 0 0 1 1.255.52l.094.319c.527 1.79 3.065 1.79 3.592 0l.094-.319a.873.873 0 0 1 1.255-.52l.292.16c1.64.893 3.434-.902 2.54-2.541l-.159-.292a.873.873 0 0 1 .52-1.255l.319-.094c1.79-.527 1.79-3.065 0-3.592l-.319-.094a.873.873 0 0 1-.52-1.255l.16-.292c.893-1.64-.902-3.433-2.541-2.54l-.292.159a.873.873 0 0 1-1.255-.52l-.094-.319zm-2.633.283c.246-.835 1.428-.835 1.674 0l.094.319a1.873 1.873 0 0 0 2.693 1.115l.291-.16c.764-.415 1.6.42 1.184 1.185l-.159.292a1.873 1.873 0 0 0 1.116 2.692l.318.094c.835.246.835 1.428 0 1.674l-.319.094a1.873 1.873 0 0 0-1.115 2.693l.16.291c.415.764-.42 1.6-1.185 1.184l-.291-.159a1.873 1.873 0 0 0-2.693 1.116l-.094.318c-.246.835-1.428.835-1.674 0l-.094-.319a1.873 1.873 0 0 0-2.692-1.115l-.292.16c-.764.415-1.6-.42-1.184-1.185l.159-.291A1.873 1.873 0 0 0 1.945 8.93l-.319-.094c-.835-.246-.835-1.428 0-1.674l.319-.094A1.873 1.873 0 0 0 3.06 4.377l-.16-.292c-.415-.764.42-1.6 1.185-1.184l.292.159a1.873 1.873 0 0 0 2.692-1.115l.094-.319z'/>" + '</svg>' + '</button>' + "<ul class='dropdown-menu'>" + btn + '</ul>' + '</div>';
  };
  const getColumnsByOrigen = (origen: string) => {
    var columns: any = [];
    switch (origen) {
      case 'articulos':
        columns = [
          { data: 'idarticulo', title: '', sercheable: false, width: '1%' },
          { data: 'articulo', title: 'Articulo' },
          { data: 'sku', title: 'Sku' },
          { data: 'proveedor', title: 'Proveedor' },
          { data: 'precio_venta', title: 'Pr. Venta' },
          { data: 'stock_reservado', title: 'Reservado', width: '5%' },
          { data: 'stock_disponible', title: 'Disponible', width: '5%' },
          { data: 'stock_actual', title: 'Actual', width: '5%' },
        ];
        break;
      case 'ordenes':
        columns = [
          { data: 'idorden', title: '', width: '1%', sercheable: false },
          { data: 'nroorden', title: 'Nroorden', width: '5%' },
          { data: 'fecha_alta', title: 'Fecha de alta' },
          { data: 'estado', title: 'Estado' },
          { data: 'chofer', title: 'Chofer' },
          { data: 'total', title: 'Total' },
        ];
        break;
      case 'ccorriente':
        columns = [
          { data: 'idccorriente', title: '', width: '1%', sercheable: false },
          { data: 'origen', title: 'Origen', width: '5%' },
          { data: 'tipo_movimiento', title: 'Tipo movimiento' },
          { data: 'detalle_movimiento', title: 'Detalle movimiento' },
          { data: 'tipo', title: 'Tipo' },
          { data: 'monto', title: 'Monto' },
          { data: 'fecha_alta', title: 'Fecha alta' },
        ];
        break;
      case 'entregas':
        columns = [
          { data: 'identrega', title: '', width: '1%', sercheable: false },
          { data: 'codigo', title: 'Codigo', width: '5%' },
          { data: 'total', title: 'Total', width: '5%' },
          { data: 'nro_venta', title: 'Numero de venta' },
          { data: 'direccion', title: 'Direccion' },
          { data: 'fecha_alta', title: 'Fecha de alta' },
          { data: 'codinterno', title: 'Codinterno' },
          { data: 'estado', title: 'Estado' },
        ];
        break;
      case 'ventaconsignacion':
        columns = [
          { data: 'idregistro', title: '', width: '1%', sercheable: false },
          { data: 'articulo', title: 'Articulo' },
          { data: 'idproducto', title: 'Id producto', class: 'text-center' },
          { data: 'cantidad', title: 'Cantidad' },
          { data: 'monto', title: 'Monto' },
          { data: 'fecha_alta', title: 'Fecha de alta' },
        ];
        break;
      case 'stock':
        columns = [
          { data: 'idtipo', title: '', sercheable: false, visible: false, width: '1%' },
          { data: 'tipo', title: 'Tipo' },
          { data: 'cantidad', title: 'Cant.' },
          { data: 'fecha_alta', title: 'Fecha' },
          { data: 'detalle', title: 'Detalle' },
        ];
        break;
      default:
        columns = [];
    }
    return columns;
  };
  const getRenderColumns = useCallback((origen: string) => {
    switch (origen) {
      case 'stock':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return '';
            },
          },
          {
            targets: [1],
            render: function (data: any, type: any, full: any) {
              if (full['idtipo'] === 1) {
                return "<span style='color:green' title='" + full['tipo'] + "' class='material-symbols-outlined'>arrow_upward</span>";
              } else {
                return "<span style='color:red' title='" + full['tipo'] + "' class='material-symbols-outlined'>arrow_downward</span>";
              }
            },
          },
          {
            targets: [3],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'ordenes':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, false, origen, full);
            },
          },
          {
            targets: [2],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'ccorriente':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, false, origen, full);
            },
          },
          {
            targets: [6],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'entregas':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, false, origen, full);
            },
          },
          {
            targets: [5],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      case 'ventaconsignacion':
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, false, origen, full);
            },
          },
          {
            targets: [5],
            render: function (data: any, type: any, full: any) {
              return moment(data).format('DD/MM/YYYY');
            },
          },
        ];
      default:
        return [
          {
            targets: [0],
            render: function (data: any, type: any, full: any) {
              return getButtonOpciones(data, false, origen, full);
            },
          },
        ];
    }
  }, []);
  const getMetodoOrigen = (origen: string) => {
    var metodo = '';
    switch (origen) {
      case 'articulos':
        metodo = 'POST';
        break;
      case 'ordenes':
        metodo = 'POST';
        break;
      case 'ccorriente':
        metodo = 'POST';
        break;
      case 'entregas':
        metodo = 'POST';
        break;
      case 'ventaconsignacion':
        metodo = 'POST';
        break;
      default:
        metodo = 'GET';
    }
    return metodo;
  };
  const getDataOrigen = (origen: string) => {
    let filtros = localStorage.getItem(`filtros${origen}`)?.length ? JSON.parse(localStorage.getItem(`filtros${origen}`) ?? '{}') : null;
    var data: any = {};
    switch (origen) {
      case 'articulos': {
        if (!filtros) {
          data['articulo'] = '';
          data['idarticulo'] = 0;
          data['idmarca'] = 0;
          data['idmodelo'] = 0;
          data['idproveedor'] = 0;
          data['idtipo'] = 0;
          break;
        } else {
          data['articulo'] = filtros.articulo;
          data['idarticulo'] = filtros.idarticulo?.value;
          data['idmarca'] = filtros.idmarca?.value;
          data['idmodelo'] = filtros.idmodelo?.value;
          data['idproveedor'] = filtros.idproveedor?.value;
          data['idtipo'] = filtros.idtipo?.value;
          break;
        }
      }
      case 'ordenes': {
        if (!filtros) {
          data['idorden'] = 0;
          data['idchofer'] = 0;
          data['idestado'] = 0;
          data['nroorden'] = '';
          data['producto'] = '';
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').format();
          break;
        } else {
          data['idorden'] = 0;
          data['idchofer'] = typeof filtros.idchofer === 'object' ? filtros.idchofer?.value : 0;
          data['idestado'] = typeof filtros.idestado === 'object' ? filtros.idestado?.value : 0;
          data['nroorden'] = filtros.nroorden;
          data['producto'] = filtros.producto;
          data['fecha_inicio'] = filtros?.fecha_inicio?.length ? filtros.fecha_inicio : moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = filtros?.fecha_fin?.length ? filtros.fecha_fin : moment().tz('America/Argentina/Buenos_Aires').format();
          break;
        }
      }
      case 'ccorriente': {
        if (!filtros) {
          data['idccorriente'] = 0;
          data['idorigen'] = 0;
          data['idtipomovimiento'] = 0;
          data['detallemovimiento'] = '';
          data['idtipo'] = '';
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').startOf('day').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').endOf('day').format();
          break;
        } else {
          data['idccorriente'] = 0;
          data['idorigen'] = filtros.idorigen?.value;
          data['idtipomovimiento'] = filtros.idtipomovimiento?.value;
          data['detallemovimiento'] = filtros.detallemovimiento;
          data['idtipo'] = filtros.idtipo?.value;
          data['fecha_inicio'] = filtros.fecha_inicio;
          data['fecha_fin'] = filtros.fecha_fin;
          break;
        }
      }
      case 'entregas': {
        if (!filtros) {
          data['identrega'] = 0;
          data['idestado'] = 0;
          data['codinterno'] = '';
          data['nroventa'] = '';
          data['producto'] = '';
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').format();
          break;
        } else {
          data['identrega'] = 0;
          data['idestado'] = typeof filtros.idestado === 'string' ? 0 : filtros.idestado?.value;
          data['codinterno'] = filtros.codinterno;
          data['nroventa'] = filtros.nroventa;
          data['producto'] = filtros.producto;
          data['fecha_inicio'] = filtros.fecha_inicio;
          data['fecha_fin'] = filtros.fecha_fin;
          break;
        }
      }
      case 'ventaconsignacion': {
        if (!filtros) {
          data['identrega'] = 0;
          data['idproducto'] = 0;
          data['fecha_inicio'] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format();
          data['fecha_fin'] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
          break;
        } else {
          data['identrega'] = 0;
          data['idproducto'] = filtros.idproducto?.value;
          data['fecha_inicio'] = filtros.fecha_inicio;
          data['fecha_fin'] = filtros.fecha_fin;
          break;
        }
      }
      default:
        data = [];
    }
    return JSON.stringify(data);
  };
  const orderfixedtogroup_grid = (tipo: string, groupColumn: any) => {
    switch (tipo) {
      default:
        return '';
    }
  };
  const getColumnasExportar = (origen: string) => {
    switch (origen) {
      case 'articulos':
        return [1, 2, 3, 4, 5, 6, 7];
      case 'stock':
        return [1, 2, 3, 4];
      default:
        return [];
    }
  };
  const getExportOrientation = (origen: string) => {
    switch (origen) {
      default:
        return 'portrait';
    }
  };
  const ManejoOrigenAcciones = useCallback(
    (origen: string, e: any) => {
      if (manejo_acciones) {
        switch (origen) {
          default:
            return manejo_acciones(origen, e?.target?.attributes?.key?.value, e.target.attributes.accion.value);
        }
      }
    },
    [manejo_acciones]
  );
  const getButtonsGrids = useCallback((origen: string) => {
    switch (origen) {
      case 'stock':
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
        ];
      case 'ventaconsignacion':
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
          {
            text: "<a accion='a' class='a-filtro'>Filtros</a>",
            className: 'btn-filtro-tabla',
          },
          {
            text: "<a accion='crear' class='a-filtro'>Crear</a>",
            className: 'btn-crear-consignacion',
          },
        ];
      default:
        return [
          {
            extend: 'collection',
            text: 'Exportar',
            buttons: [
              { extend: 'excel', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'pdfHtml5', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen), grouped_array_index: [groupColumn] } },
              { extend: 'csv', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
              { extend: 'print', orientation: getExportOrientation(origen), exportOptions: { columns: getColumnasExportar(origen) } },
            ],
          },
          {
            text: 'Refresh',
            action: function (e: any, dt: any, node: any, config: any) {
              recargaGrid(moment().format('YYYY-MM-DD HH:mm:ss'));
            },
          },
          {
            text: "<a accion='a' class='a-filtro'>Filtros</a>",
            className: 'btn-filtro-tabla',
          },
        ];
    }
  }, []);
  function getDom(origen: string) {
    switch (origen) {
      case 'stock':
        return '<"row "<"col-12"<"row"<"col-12 div-btn-grid"B>>><"col-12"<"row"<"col-12 div-filter-table"f>>>><"row"<"col-12 content-grid-referencia">><"row"<"col-12"t>><"row grid-footer mt-2"<"col-12 col-lg-4"l><"col-12 col-lg-8"<"row"<"col-12 col-lg-4 col-xl-4"i><"col-12 col-lg-8 col-xl-8"p>>>>';
      default:
        return '<"row "<"col-12 col-md-7 col-lg-8"<"row"<"col-12 div-btn-grid"B>>><"col-12 d-block d-lg-flex w-auto justify-content-end col-md-5 col-lg-4"<"row"<"col-12 w-auto div-filter-table"f>>>><"row"<"col-12 content-grid-referencia">><"row"<"col-12"t>><"row grid-footer mt-2"<"col-12 col-lg-4"l><"col-12 col-lg-8"<"row"<"col-12 col-lg-4 col-xl-4"i><"col-12 col-lg-8 col-xl-8"p>>>>';
    }
  }
  const tableOp: React.RefObject<HTMLTableElement> = useRef(null);
  useEffect(() => {
    $(tableOp.current).DataTable({
      colReorder: true,
      destroy: true,
      responsive: false,
      processing: true,
      ajax: {
        contentType: 'application/json',
        type: getMetodoOrigen(origen),
        url: getUrlOrigen(origen),
        dataSrc: 'content',
        data: function () {
          return getDataOrigen(origen);
        },
      },
      dom: getDom(origen),
      buttons: getButtonsGrids(origen),
      drawCallback: function (settings: any) {
        if ($('.tabla-agrupada').length > 0) {
          var api = this.api();
          var rows = api.rows({ page: 'current' }).nodes();
          var last: any = null;
          api
            .column(groupColumn, { page: 'current' })
            .data()
            .each(function (group: any, i: any) {
              if (last !== group) {
                $(rows)
                  .eq(i)
                  .before('<tr class="group tr_agrupado"><td class="table-secondary" colspan="11">' + group + '</td></tr>');
                last = group;
              }
            });
        }
        $('.div-btn-grid .btn,.btn-group-sm .btn').addClass('btn-sm');
        $('#tabla_' + origen + ' .dropdown-item').on('click', function (e: any) {
          ManejoOrigenAcciones(origen, e);
        });
        $('.a-filtro').on('click', function (e: any) {
          ManejoOrigenAcciones(origen, e);
        });
      },
      orderFixed: orderfixedtogroup_grid(origen, groupColumn),
      columns: getColumnsByOrigen(origen),
      displayLength: 10,
      autoWidth: false,
      columnDefs: getRenderColumns(origen),
      language: {
        paginate: {
          previous: '<',
          next: '>',
          last: '>>',
          first: '<<',
        },
        lengthMenu: 'Mostrar <select>' + '<option selected value="10">10</option>' + '<option value="25">25</option>' + '<option value="50">50</option>' + '<option value="75">75</option>' + '<option value="100">100</option>' + '<option value="-1">Todos</option>' + '</select> registros',
        info: 'Mostrando del _START_ a _END_ (Total: _TOTAL_ resultados)',
        infoFiltered: ' - filtrados de _MAX_ registros',
        infoEmpty: 'No hay resultados de b&uacute;squeda',
        zeroRecords: 'No hay registros a mostrar',
        processing: 'Espere, por favor...',
        search: 'Buscar: ',
      },
    });
  }, [origen, gridCarga /* , recargaGrid, ManejoOrigenAcciones, getButtonsGrids, getRenderColumns, getUrlOrigen */]);
  return <Table responsive='xl' id={'tabla_' + origen} className={getTipoTable(origen)} bordered hover ref={tableOp}></Table>;
};

export default Grid;
