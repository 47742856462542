import EurekaGeneral, { PutEureka } from '../../../estructura/EurekaGeneral/EurekaGeneral';

interface IForm {
  ListaChecks: ILista[];
  SetRefresh: Function;
  ModalClose: Function;
}

interface ILista {
  nroorden: string;
  id: number;
}

export const FormConfirmadosOrdenes: React.FC<IForm> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  function submit_confirmar_ordenes() {
    let arrayPromesas = ListaChecks.map(async (value) => await PutEureka(`/Ordenes/Confirmar/${value.id}`, { entregas: ListaChecks }));
    Promise.all(arrayPromesas).then((response) => {
      if (response[0].info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroorden}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary btn-sm' onClick={submit_confirmar_ordenes}>
          Confirmar entregas
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};
export const FormCanceladosOrdenes: React.FC<IForm> = ({ ListaChecks = [], SetRefresh, ModalClose }) => {
  function submit_cancelar_ordenes() {
    let arrayPromesas = ListaChecks.map(async (value) => await PutEureka(`/Ordenes/Rechazar/${value.id}`, { entregas: ListaChecks }));
    Promise.all(arrayPromesas).then((response) => {
      if (response[0].info) {
        document.querySelectorAll<HTMLInputElement>('[class^="chk_"]:checked').forEach((input) => {
          input.checked = false;
        });
        SetRefresh();
        ModalClose();
      }
    });
  }
  const columnCount = EurekaGeneral.VerificarDivisibilidad(ListaChecks.length);
  if (!Number.isNaN(columnCount) && columnCount > 0 && ListaChecks.length > 0) {
    const rowCount = Math.ceil(ListaChecks.length / columnCount);
    const tableData = Array.from({ length: rowCount }, (_, rowIndex) => ListaChecks.slice(rowIndex * columnCount, (rowIndex + 1) * columnCount));
    return (
      <>
        <table className='table table-striped-columns'>
          <thead>
            <tr className='text-center'>
              {[...Array(columnCount)].map((_, i) => (
                <th key={i}># venta</th>
              ))}
            </tr>
          </thead>
          <tbody>
            {tableData.map((row, rowIndex) => (
              <tr key={rowIndex}>
                {row.map((observado, columnIndex) => (
                  <td className='text-center' key={columnIndex}>
                    {observado.nroorden}
                  </td>
                ))}
              </tr>
            ))}
          </tbody>
        </table>
        <button className='w-100 mc-btn primary btn-sm' onClick={submit_cancelar_ordenes}>
          Cancelar entregas
        </button>
      </>
    );
  } else {
    return (
      <div className='alert alert-danger' role='alert'>
        No hay entregas seleccionadas
      </div>
    );
  }
};
export default function FormGenericos() {
  return <></>;
}
