import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Table, Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment';

interface IFormEntregas {
  estadoEntregas: { show: boolean; id: number; accion: string };
  formDisabled: boolean;
  close: Function;
}

interface IUseForm {
  codinterno: string;
  nroventa: string;
  direccion: string;
  fecha_alta: string;
  productos: { articulo: string; sku: string; cantidad: number }[];
}

const FormEntregas: React.FC<IFormEntregas> = ({ estadoEntregas, formDisabled, close }) => {
  const { register, setValue, getValues, reset } = useForm<IUseForm>({
    defaultValues: {
      codinterno: '',
      nroventa: '',
      direccion: '',
      fecha_alta: '',
      productos: [],
    },
  });
  const articulobyid = useCallback(
    (registro: number, accion: string) => {
      GetEureka('/Entregas/' + registro).then((response) => {
        if (response.info) {
          var registro = response.content[0];
          setValue('codinterno', registro.codintero);
          setValue('nroventa', registro.nro_venta);
          setValue('direccion', registro.direccion);
          setValue('fecha_alta', moment(registro.fecha_alta).format('DD/MM/YYYY'));
          setValue('productos', registro.productos);
          reset({
            codinterno: registro.codinterno,
            nroventa: registro.nro_venta,
            direccion: registro.direccion,
            fecha_alta: moment(registro.fecha_alta).format('DD/MM/YYYY'),
            productos: registro.productos,
          });
        } else {
          console.log(response);
        }
      });
    },
    [reset, setValue]
  );
  useEffect(() => {
    if (estadoEntregas.show) articulobyid(estadoEntregas.id, estadoEntregas.accion);
  }, [articulobyid, estadoEntregas.accion, estadoEntregas.id]);
  function cerrar(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    close();
  }
  return (
    <>
      <form className='row'>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='chofer'>Cod interno</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('codinterno')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='nroorden'>Numero de venta</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('nroventa')} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='estado'>Direccion</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('direccion')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='fecha_alta'>Fecha de alta</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('fecha_alta')} />
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <Form.Label htmlFor='productos'>Productos</Form.Label>
          <div className='lineaTabla'></div>
          <Table bordered hover size='sm' className='tablaReact'>
            <thead>
              <tr>
                <th className='colorLetra'>Descripcion</th>
                <th className='colorLetra'>Sku</th>
                <th className='colorLetra'>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {getValues()?.productos?.map((produc, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <p className='colorLetra f-13'>{produc.articulo}</p>
                    </td>
                    <td>
                      <p className='colorLetra f-13'>{produc.sku}</p>
                    </td>
                    <td>
                      <p className='colorLetra f-13'>{produc.cantidad}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-sm background-roj' onClick={cerrar}>
            Cerrar
          </button>
        </div>
      </form>
    </>
  );
};
export default FormEntregas;
