import React, { useState } from 'react';
import useGetComplexObject from '../../hooks/useGetComplexObject.ts';
import { Box, Form, Heading, Button, Anchor, Image, Text } from '../../components/elements/index.ts';
import IconField from '../../components/fields/IconField';
import Logo from '../../components/Logo.tsx';
import data from '../../data/master/login.json';
import { useSignInWithGoogle, useSignInWithApple, useSignInWithFacebook } from 'react-firebase-hooks/auth';
// import { auth } from '../../firebase/clientApp.ts';
import { useNavigate } from 'react-router-dom';
import logo from '../../images/logo/logo/72/Logo_colores_72_original copia 3.png';
import { FlexitContext } from '../../context/Flexit.tsx';
import { PostEureka } from '../../components-new/estructura/EurekaGeneral/EurekaGeneral.ts';
const { REACT_APP_TEXTO, REACT_APP_HIDDEN_LOGIN_FIREBASE, REACT_APP_FRONT_VERSION } = process.env;

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

export default function Login() {
  const { setearDatos, tokenDecifrado } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [loading, setLoading] = useState(true);
  const [usuario, setUsuario] = useState({ username: '', clave: '' });
  const [mensajeErrorLocal, setMensajeErrorLocal] = useState('');
  // const [SignInWithGoogle] = useSignInWithGoogle(auth);
  // const [SignInWithFacebook] = useSignInWithFacebook(auth);
  // const [SignInWithApple] = useSignInWithApple(auth);
  const navigate = useNavigate();

  async function logeando(e: any, usuario?: any) {
    e.preventDefault();
    setLoading(false);
    var params = { ...usuario, idusuario: null, sandbox: true };
    PostEureka(`/Autenticacion/AutenticarUsuario`, params).then((res) => {
      localStorage.setItem('token', res.data.content.token);
      const datos: any = tokenDecifrado(res.data.content.token);
      setearDatos(datos.data);
      navigate('/dashboard');
      setLoading(true);
    });
  }

  const logeoDeUsuario = async (e: React.FormEvent<HTMLFormElement>) => {
    e.preventDefault();
    setLoading(false);
    try {
      const response = await PostEureka(`/Autenticacion/AutenticarUsuario`, {
        ...usuario,
        idusuario: null,
        sandbox: true,
      });
      let token = response.content.token;
      localStorage.setItem('token', token);
      const datos = await tokenDecifrado(token);
      setearDatos(datos.data);
      setLoading(true);
      setMensajeErrorLocal('');
      navigate('/dashboard');
    } catch (error: any) {
      setMensajeErrorLocal(error.response.data.msg);
      setLoading(true);
    }
  };

  const cambiarValoresInputs = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUsuario({ ...usuario, [e.target.name]: e.target.value });
  };

  function returnType(value: string): 'submit' | 'button' | 'reset' | undefined {
    if (value === ('submit' || 'button' || 'reset')) return value;
    else return undefined;
  }

  return (
    <Box className='mc-auth'>
      <Image className='mc-auth-pattern' />
      <Box className='mc-auth-group'>
        <Logo src={logo} alt={data?.logo.alt} className='shadow-lg-eureka mc-auth-logo' />
        <Form className='shadow-lg-eureka mc-auth-form' onSubmit={logeoDeUsuario}>
          <span className='badge text-bg-flexit mb-3'>{REACT_APP_TEXTO}</span>
          <Heading as='h4' className='mc-auth-title'>
            {'Area flexpoints'}
          </Heading>
          <Heading as='h4' className='mc-auth-title'>
            {'Inicio de sesion'}
          </Heading>
          <IconField icon={'person'} type={'text'} option={undefined} classes={'w-100 h-sm'} className='rounded-end' placeholder={'Ingrese su usuario'} name='username' value={usuario.username} onChangeInput={cambiarValoresInputs} />
          <IconField icon={'lock'} type={'password'} option={undefined} classes={'w-100 h-sm'} className='rounded-end' placeholder={'Ingrese su contraseña'} name='clave' value={usuario.clave} onChangeInput={cambiarValoresInputs} />
          <Button className={`mc-auth-btn ${data?.button.fieldSize}`} type={returnType(data?.button.type)}>
            <div className='spinner-border spinner-flexit spinner' role='status' hidden={loading}>
              <span className='visually-hidden'>Loading...</span>
            </div>
            {data?.button.text}
          </Button>
          <p className='textoError' hidden={!mensajeErrorLocal?.length}>
            {mensajeErrorLocal}
          </p>
          {/* <Box className='mc-auth-connect' hidden={REACT_APP_HIDDEN_LOGIN_FIREBASE}>
            <Box className='mc-auth-divide'>
              <Text as='span'>{data?.divide.text}</Text>
            </Box>
            {data?.connect.map((item, index) => (
              <Anchor
                key={index}
                className={`${item.path === 'google' ? item.classes : `${item.classes} desactivado`}`}
                onClick={() => {
                  if (item.path === 'google') {
                    SignInWithGoogle().then((user) => logeando(user));
                  }
                  if (item.path === 'facebook') {
                    SignInWithFacebook().then((user) => logeando(user));
                  }
                  if (item.path === 'apple') {
                    SignInWithApple().then((user) => logeando(user));
                  }
                }}>
                <i className={item.icon}></i>
                <span>{item.text}</span>
              </Anchor>
            ))}
          </Box> */}
          <small>v. {REACT_APP_FRONT_VERSION}</small>
        </Form>
      </Box>
    </Box>
  );
}
