import { useEffect, useState } from 'react';
import PlaceholderNotificaciones from '../../components/placeholders/PlaceholderNotificaciones';
import { List, Box, Icon, Text, Item, Anchor, Button } from '../../components/elements';
import { CardLayout, CardHeader } from '../../components/cards';
import PageLayout from '../../layouts/PageLayout';
import data from '../../data/master/notification.json';
import { DeleteEureka, PostEureka } from '../../components-new/estructura/EurekaGeneral/EurekaGeneral';
import { FlexitContext } from '../../context/Flexit';
import useGetComplexObject from '../../hooks/useGetComplexObject';

interface INotificaciones {
  subject: string;
  mensaje: string;
  diferencia: string;
  idnotificacion: number;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

export default function Notification() {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [notificaciones, setNotificaciones] = useState<INotificaciones[]>([]);
  const [loading, setLoading] = useState(true);
  const deleteNotificacion = (idnotificacion: number | undefined = undefined, idusuario: number | undefined = undefined) => {
    setLoading(true);
    let objetoNotificacion: any = { idnotificacion, idusuario };
    for (let key in objetoNotificacion) {
      if (objetoNotificacion[`${key}`] === undefined) delete objetoNotificacion[`${key}`];
    }
    DeleteEureka('/notificaciones', objetoNotificacion).then(() => getNotificaciones());
    setLoading(false);
  };

  async function getNotificaciones() {
    setLoading(true);
    const notificaciones = await PostEureka('/Notificaciones', {});
    setNotificaciones(notificaciones.content);
    setLoading(false);
  }

  useEffect(() => {
    getNotificaciones();
  }, []);

  return (
    <>
      {loading ? (
        <PageLayout>
          <PlaceholderNotificaciones />
        </PageLayout>
      ) : (
        <>
          {notificaciones?.length ? (
            <PageLayout>
              <CardLayout>
                <CardHeader title='Notificaciones' dotsMenu={data?.dotsMenu} onClick={() => deleteNotificacion(undefined, datos.usuario.idusuario)} />
                <List className='mc-notify-list'>
                  {notificaciones?.map((item, index) => (
                    <Item className='mc-notify-item' key={index}>
                      <Anchor className='mc-notify-content' /* href={item.path} */>
                        <Box className='mc-notify-media-flexit'>
                          {/* <Image src={item.src} alt={item.alt} /> */}
                          <Icon className={`material-icons ${'mail'}`}>{'mail'}</Icon>
                        </Box>
                        <Box className='mc-notify-meta'>
                          <Text as='span'>
                            <Text as='b'>{item?.subject}</Text>
                          </Text>
                          <Text as='b' className='mensaje-notificacion-flexit'>
                            {item?.mensaje}
                          </Text>
                          <Text as='small'>{item?.diferencia}</Text>
                        </Box>
                      </Anchor>
                      <Button className='mc-notify-close' onClick={() => deleteNotificacion(item?.idnotificacion)}>
                        <Icon>{'close'}</Icon>
                      </Button>
                    </Item>
                  ))}
                </List>
              </CardLayout>
            </PageLayout>
          ) : (
            <PageLayout>
              <div className='sinOrdenes' style={{ height: '400px' }}>
                Sin notificaciones
              </div>
            </PageLayout>
          )}
        </>
      )}
    </>
  );
}
