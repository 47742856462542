import React, { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment-timezone';

interface IFormVentaconsignacion {
  estadoVentaconsignacion: { show: boolean; id: number; accion: string };
  formDisabled: boolean;
  close: Function;
}

interface IUseForm {
  articulo: string;
  cantidad: string;
  monto: string;
  fecha_alta: string;
}

const FormVentaconsignacion: React.FC<IFormVentaconsignacion> = ({ estadoVentaconsignacion, formDisabled, close }) => {
  const { register, setValue, reset } = useForm<IUseForm>({
    defaultValues: {
      articulo: '',
      cantidad: '',
      monto: '',
      fecha_alta: '',
    },
  });

  const articulobyid = useCallback(
    (registro: number, accion: string) => {
      GetEureka('/Ventaconsignacion/' + registro).then((response) => {
        if (response.info) {
          var registro = response.content[0];
          setValue('articulo', registro.articulo);
          setValue('cantidad', registro.cantidad);
          setValue('monto', registro.monto);
          setValue('fecha_alta', moment(registro.fecha_alta).format('DD/MM/YYYY'));
          reset({
            articulo: registro.articulo,
            cantidad: registro.cantidad,
            monto: registro.monto,
            fecha_alta: moment(registro.fecha_alta).format('DD/MM/YYYY'),
          });
        } else {
          console.log(response);
        }
      });
    },
    [reset, setValue]
  );

  useEffect(() => {
    if (estadoVentaconsignacion.show) articulobyid(estadoVentaconsignacion.id, estadoVentaconsignacion.accion);
  }, [articulobyid, estadoVentaconsignacion.accion, estadoVentaconsignacion.id, estadoVentaconsignacion.show]);

  function cerrar(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    close();
  }

  return (
    <>
      <form className='row'>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='chofer'>Articulo</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('articulo')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='nroorden'>Cantidad</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('cantidad')} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='estado'>Monto</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('monto')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='fecha_alta'>Fecha de alta</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('fecha_alta')} />
            </div>
          </div>
        </div>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-sm background-roj' onClick={cerrar}>
            Cerrar
          </button>
        </div>
      </form>
    </>
  );
};
export default FormVentaconsignacion;
