import React, { useEffect, useState } from 'react';
import { Heading } from './elements';
import { cambioPrecioDecimales } from '../helpers';
import { useLocation } from 'react-router-dom';
import { GetEureka } from '../components-new/estructura/EurekaGeneral/EurekaGeneral';

interface IBotonesHeader {
  label: string;
}

interface IDashboard {
  ccorriente_diario: IDiario[];
  conteo_diario: IConteoDiario;
  conteo_mensual: { consignacion: number; entregas: number; ordenes: number; saldoactual: number };
  productos: IProducto[];
  saldo_actual: number;
  usuario: IUsuario;
}

interface IConteoDiario {
  [key: string]: any;
  consignacion: IDiario[];
  entregas: IDiario[];
  ordenes: IDiario[];
}

interface IDiario {
  fecha: string;
  cantidad: number;
}

interface IProducto {
  idproducto: number;
  producto: string;
  stock: number;
}

interface IUsuario {
  apellidos: string;
  idusuario: number;
  imagen: string;
  nombres: string;
  timezone: string;
  username: string;
}

const BotonesHeader: React.FC<IBotonesHeader> = ({ label }) => {
  const location = useLocation();
  const [dashboard, setDashboard] = useState<IDashboard>();
  const posicionActual = location.pathname?.split('/')[1].toLocaleLowerCase();
  useEffect(() => {
    if (posicionActual === 'ccorriente' && localStorage.getItem('token')) GetEureka(`/Dashboard/7`).then((response) => setDashboard(response.content));
  }, [posicionActual]);
  return (
    <div className='d-flex align-items-center'>
      <div className='d-flex flex-column justify-content-between align-items-end col-9'>
        <div className='d-flex w-100 align-items-center justify-content-start col-12'>
          <Heading className='mc-breadcrumb-title'>{label}</Heading>
        </div>
        <div className='col-12 ms-2' hidden={posicionActual === 'ccorriente' ? false : true}>
          <h4 className='colorLetra' id='saldo'>
            Saldo: {(Object.keys(dashboard || {})?.length && cambioPrecioDecimales(dashboard?.saldo_actual)) || '0.00'} ARS
          </h4>
        </div>
      </div>
    </div>
  );
};

export default BotonesHeader;
