import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment';

interface IFiltrosArticulos {
  estadoArticulos: { show: boolean; id: number; accion: string };
  formDisabled?: boolean;
  close: Function;
  setRecargaGridArticulos: Function;
}

interface ISelect {
  value: number;
  label: string;
  name: string;
}

interface IUseForm {
  idarticulo: ISelect;
  articulo: string;
  idmarca: ISelect;
  idmodelo: ISelect;
  idproveedor: ISelect;
  idtipo: ISelect;
}

const FiltrosArticulos: React.FC<IFiltrosArticulos> = ({ estadoArticulos, formDisabled, close, setRecargaGridArticulos }) => {
  const [selectKey1, setSelectKey1] = useState(0);
  const [selectKey2, setSelectKey2] = useState(2);
  const [selectKey3, setSelectKey3] = useState(4);
  const [selectKey4, setSelectKey4] = useState(6);
  const localFiltros = localStorage.getItem('filtrosarticulos')?.length ? JSON.parse(localStorage.getItem('filtrosarticulos') ?? '{}') : localStorage.getItem('filtrosarticulos');
  const [combos, setCombos] = useState({
    marcas: [],
    modelos: [],
    proveedores: [],
    tipos: [],
  });
  const { register, handleSubmit, setValue, getValues, reset } = useForm<IUseForm>({
    defaultValues: {
      idarticulo: { value: 0, label: 'Select...', name: 'idarticulo' },
      articulo: '',
      idmarca: { value: 0, label: 'Select...', name: 'idmarca' },
      idmodelo: { value: 0, label: 'Select...', name: 'idmodelo' },
      idproveedor: { value: 0, label: 'Select...', name: 'idproveedor' },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    },
  });

  useEffect(() => {
    let filtros = JSON.parse(localStorage.getItem('filtrosarticulos') ?? '{}');
    const expectedStructure = {
      idarticulo: { value: 0, label: 'Select...', name: 'idarticulo' },
      articulo: '',
      idmarca: { value: 1, label: 'Marca Generica', name: 'idmarca' },
      idmodelo: { value: 0, label: 'Select...', name: 'idmodelo' },
      idproveedor: { value: 0, label: 'Select...', name: 'idproveedor' },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    };

    const isValidStructure = (obj: any, structure: any): boolean => {
      const objKeys = Object.keys(obj);
      const structureKeys = ['articulo', 'idarticulo', 'idmarca', 'idmodelo', 'idproveedor', 'idtipo'];
      if (objKeys.length !== structureKeys.length) return false;
      return structureKeys.every((key: string) => {
        if (typeof structure === 'object' && !Array.isArray(structure) && obj.hasOwnProperty(key) && structure.hasOwnProperty(key)) {
          return isValidStructure(obj[`${key}`], structure[`${key}`]);
        }
        return obj.hasOwnProperty(key) && structure.hasOwnProperty(key) && typeof obj[`${key}`] === typeof structure[`${key}`];
      });
    };

    if (filtros && isValidStructure(filtros, expectedStructure)) {
      for (let prop in filtros) {
        if (prop === ('idarticulo' || 'articulo' || 'idmarca' || 'idmodelo' || 'idproveedor' || 'idtipo')) setValue(prop, filtros[`${prop}`]);
      }
    }
    combosArticulos(estadoArticulos.id, estadoArticulos.accion);
  }, [setValue, estadoArticulos.accion, estadoArticulos.id]);

  const combosArticulos = (registro: number, accion: string) => {
    GetEureka('/articulos/combos').then((response) => {
      if (response.info) {
        var registro = response.content;
        setCombos({
          marcas: registro.marcas,
          modelos: registro.modelos,
          proveedores: registro.proveedores,
          tipos: registro.tipos,
        });
      } else {
        console.log(response);
      }
    });
  };

  function cambioSelect(e: any) {
    if (e.name === 'articulo') {
      const sanitizedValue = e.value.replace(/[^a-zA-Z0-9 ]/g, '');
      setValue(e.name, sanitizedValue);
      reset({ ...getValues(), [e.name]: sanitizedValue });
    } else {
      setValue(e.name, e);
      reset({ ...getValues(), [e.name]: e });
    }
  }

  function filterArticles(e: any) {
    const sanitizedData = JSON.stringify(e);
    localStorage.setItem('filtrosarticulos', sanitizedData);
    setRecargaGridArticulos(moment().format('YYYY-MM-DD HH:mm:ss'));
    close();
  }

  const devolverDefault = (valor: { value: number; label: string; name: string }, select: string) => {
    switch (select) {
      case 'marcas':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idmarca' };
      case 'modelos':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idmodelo' };
      case 'proveedores':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idproveedor' };
      case 'tipos':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idtipos' };
      default:
        return valor ? valor : { value: 0, label: 'Select...', name: 'idmarca' };
    }
  };

  const resetearFiltros = (e: React.MouseEvent<HTMLButtonElement>, origen: string) => {
    e.preventDefault();
    let reseteo = {
      articulo: '',
      idarticulo: { value: 0, label: 'Select...', name: 'idarticulo' },
      idmarca: { value: 0, label: 'Select...', name: 'idmarca' },
      idmodelo: { value: 0, label: 'Select...', name: 'idmodelo' },
      idproveedor: { value: 0, label: 'Select...', name: 'idproveedor' },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    };
    const sanitizedData = JSON.stringify(reseteo);
    localStorage.setItem('filtros' + origen, sanitizedData);
    for (let i in reset) {
      if (i === ('idarticulo' || 'articulo' || 'idmarca' || 'idmodelo' || 'idproveedor' || 'idtipo')) setValue(i, reseteo[`${i}`]);
    }
    reset({
      articulo: '',
      idarticulo: { value: 0, label: 'Select...', name: 'idarticulo' },
      idmarca: { value: 0, label: 'Select...', name: 'idmarca' },
      idmodelo: { value: 0, label: 'Select...', name: 'idmodelo' },
      idproveedor: { value: 0, label: 'Select...', name: 'idproveedor' },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    });
    setSelectKey1(selectKey1 + 1);
    setSelectKey2(selectKey2 + 1);
    setSelectKey3(selectKey3 + 1);
    setSelectKey4(selectKey4 + 1);
  };

  const cerrar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    close();
  };

  return (
    <div className='d-flex flex-column justify-content-between height-100'>
      <form className='row height-100' onSubmit={handleSubmit(filterArticles)}>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-12'>
              <Form.Label htmlFor='articulo'>Articulo</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('articulo')} />
            </div>
          </div>
          <div className='col-12'>
            <Form.Label htmlFor='articulo'>Marcas</Form.Label>
            <Select
              key={selectKey1}
              className='select-articulos'
              defaultValue={devolverDefault(localFiltros?.idmarca, 'marcas')}
              options={[
                { value: 0, label: 'Select...', name: 'idmarca' },
                ...combos.marcas.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idmarca',
                })),
              ]}
              onChange={cambioSelect}
            />
            <Form.Label htmlFor='articulo'>Modelos</Form.Label>
            <Select
              key={selectKey2}
              className='select-articulos'
              defaultValue={devolverDefault(localFiltros?.idmodelo, 'modelos')}
              options={[
                { value: 0, label: 'Select...', name: 'idmodelo' },
                ...combos.modelos.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idmodelo',
                })),
              ]}
              onChange={cambioSelect}
            />
            <Form.Label htmlFor='articulo'>Proveedores</Form.Label>
            <Select
              key={selectKey3}
              className='select-articulos'
              defaultValue={devolverDefault(localFiltros?.idproveedor, 'proveedores')}
              options={[
                { value: 0, label: 'Select...', name: 'idproveedor' },
                ...combos.proveedores.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idproveedor',
                })),
              ]}
              onChange={cambioSelect}
            />
            <Form.Label htmlFor='articulo'>Tipos</Form.Label>
            <Select
              key={selectKey4}
              className='select-articulos'
              defaultValue={devolverDefault(localFiltros?.idtipo, 'tipos')}
              options={[
                { value: 0, label: 'Select...', name: 'idtipo' },
                ...combos.tipos.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idtipo',
                })),
              ]}
              onChange={cambioSelect}
            />
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-end botones-offcanvas'>
          <button className='btn background-roj btn-sm' onClick={cerrar}>
            Cerrar
          </button>
          <button className='btn background-ama btn-sm' onClick={(e) => resetearFiltros(e, 'articulos')}>
            Resetear
          </button>
          <button type='submit' className='btn background-ver btn-sm'>
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
};
export default FiltrosArticulos;
