import React, { useEffect, useState } from 'react';
import EurekaOrdenesPendientes from '../Ordenes/EurekaOrdenesPendientes';
import EurekaOrdenesEnCamino from '../Ordenes/EurekaOrdenesEnCamino';
import EurekaOrdenesFinalizadas from '../Ordenes/EurekaOrdenesFinalizadas';
import EurekaOffcanvas from '../../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltroTableroOrdenes from './FiltroOrdenes';
import { GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTableList, faTableColumns } from '@fortawesome/free-solid-svg-icons';
import PageLayout from '../../../layouts/PageLayout';
import { useForm } from 'react-hook-form';
import moment from 'moment';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
const { REACT_APP_DIAS_DASHBOARD } = process.env;

var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).add(1, 'days').format('YYYY-MM-DD');

interface IUseForm {
  fecha_desde: string;
  fecha_hasta: string;
  idchofer: number;
  idestado: number;
  nroorden: string;
  producto: string;
}

export default function TableroOrdenes() {
  const [directionTablero, setDirectionTablero] = useState(true);
  const [refresTablero, setRefresTablero] = useState<moment.Moment>();
  const [encamino, setEnCamino] = useState([]);
  const [pendientes, setPendientes] = useState([]);
  const [finalizados, setFinalizados] = useState([]);

  const [estados, setEstados] = useState([]);
  const [choferes, setChoferes] = useState([]);

  const SetRefresh = () => setRefresTablero(moment());
  const { register, getValues, setValue } = useForm<IUseForm>({
    defaultValues: {
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
      idchofer: 0,
      idestado: 0,
      nroorden: '',
      producto: '',
    },
  });
  const [offcanvasFiltros, setoffcanvasFiltros] = useState({ show: false });
  const tooltipDirection = (props: any) => <Tooltip id='button-tooltip' {...props}>{`Cambiar tablero a ${!directionTablero ? 'Filas' : 'Columnas'}`}</Tooltip>;
  const offCanvasFiltrosClose = () => setoffcanvasFiltros({ show: false });
  const offCanvasFiltrosShow = () => setoffcanvasFiltros({ show: true });
  const SetDirection = () => setDirectionTablero(!directionTablero);
  useEffect(() => {
    var formdata = getValues();
    let filtros = localStorage.getItem('filtrosordenes')?.length ? JSON.parse(localStorage.getItem('filtrosordenes') ?? '{}') : null;
    if (filtros) {
      setValue('fecha_desde', filtros?.fecha_desde?.length ? filtros.fecha_desde : fechaDesde);
      setValue('fecha_hasta', filtros?.fecha_hasta?.length ? filtros.fecha_hasta : fechaHasta);
      setValue('idchofer', typeof filtros.idchofer === 'object' ? 0 : filtros.idchofer);
      setValue('idestado', typeof filtros.idestado === 'object' ? 0 : filtros.idestado);
      setValue('nroorden', filtros.nroorden);
      setValue('producto', filtros.producto);
      PostEureka('/ordenes/tablero', {
        ...filtros,
        idchofer: typeof filtros.idchofer === 'object' ? 0 : filtros.idchofer,
        idestado: typeof filtros.idestado === 'object' ? 0 : filtros.idestado,
      }).then((response) => {
        setEnCamino(response.content.encamino);
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
    } else {
      PostEureka('/ordenes/tablero', formdata).then((response) => {
        setEnCamino(response.content.encamino);
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
    }
    GetEureka('/ordenes/combos').then((response) => {
      setEstados(response.content.estados);
      setChoferes(response.content.choferes);
    });
  }, [refresTablero]);
  return (
    <PageLayout label='Adm. Ordenes'>
      <EurekaOffcanvas id='off-canvas-tablero-ordenes' manejador={offcanvasFiltros} offcanvasTitulo='Filtros entregas' posicion='start' handleClose={offCanvasFiltrosClose} size='eureka-offcanvas'>
        <FiltroTableroOrdenes props={{ register, SetRefresh, offCanvasFiltrosClose, estados, choferes, getValues }} />
      </EurekaOffcanvas>
      <div className='row'>
        <div className='col-md-1 mb-2'>
          <ButtonGroup>
            <Button size='sm' onClick={offCanvasFiltrosShow} variant='outline-primary'>
              Filtros
            </Button>
            <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipDirection}>
              <Button size='sm' onClick={SetDirection} variant='outline-primary'>
                <FontAwesomeIcon icon={!directionTablero ? faTableList : faTableColumns} />
              </Button>
            </OverlayTrigger>
            <Button size='sm' onClick={SetRefresh} variant='outline-primary'>
              <FontAwesomeIcon icon={faArrowsRotate} />
            </Button>
          </ButtonGroup>
        </div>
      </div>
      <div className='row justify-content-center'>
        <EurekaOrdenesPendientes direction={directionTablero} hidden={true} SetRefresh={SetRefresh} pendientes={pendientes} />
        <EurekaOrdenesEnCamino direction={directionTablero} SetRefresh={SetRefresh} encaminos={encamino} />
        <EurekaOrdenesFinalizadas direction={directionTablero} SetRefresh={SetRefresh} finalizadas={finalizados} />
      </div>
    </PageLayout>
  );
}
