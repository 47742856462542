import React from 'react';
import { Offcanvas } from 'react-bootstrap';

interface IEurekaOffcanvas {
  manejador: {
    show: boolean;
    id?: number;
    accion?: string;
  };
  children: React.ReactNode;
  offcanvasTitulo: string;
  handleClose: Function;
  posicion?: any;
  size: string;
  id: string;
}

const EurekaOffcanvas: React.FC<IEurekaOffcanvas> = ({ id, manejador, children, offcanvasTitulo, handleClose, posicion, size }) => {
  return (
    <>
      <Offcanvas id={id} className={size} placement={posicion} show={manejador.show} onHide={handleClose}>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>{offcanvasTitulo}</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body className='pt-0'>{children}</Offcanvas.Body>
      </Offcanvas>
    </>
  );
};
export default EurekaOffcanvas;
