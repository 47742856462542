import { useEffect, useRef } from 'react';
import $ from 'jquery';
import 'bootstrap-fileinput';
import 'bootstrap-icons/font/bootstrap-icons.css';
import 'bootstrap-fileinput/css/fileinput.min.css';
import 'bootstrap-fileinput/js/fileinput.min.js';

const { REACT_APP_URL_BLUEHOST } = process.env;

interface IEurekaUploadFile {
  Buttons?: Function;
  origen: string;
  ancho?: number;
  alto?: number;
  deleteImages?: Function;
  titulo: string;
  placeholder?: string;
  label?: string;
  tipo?: string;
  tipoAceptar?: string;
  clase?: string;
  disable?: boolean;
  onFileUpload?: Function;
  images?: { path: string; url: string }[];
  onCheckLenght?: Function;
  onFileUploaded: Function;
}

const EurekaUploadFile: React.FC<IEurekaUploadFile> = ({ Buttons, origen, ancho = 0, alto = 0, deleteImages = () => {}, titulo = 'Seleccione un archivo...', placeholder = 'Selecciona un archivo...', label = 'Seleccionar Archivo', tipo, tipoAceptar = '', clase = '', disable = false, onFileUploaded = () => {}, images = [], onCheckLenght = () => {} }) => {
  const refInput = useRef<HTMLInputElement | null>(null);
  function getTipoClass(clase: string) {
    if (clase !== undefined) return clase;
    else return 'file';
  }
  function getTipoAccept(tipoAccept: string) {
    switch (tipoAccept?.toLowerCase()) {
      case 'imagen':
        return 'image/*';
      case 'file':
        return '.xls, .xlsx';
      default:
        return '';
    }
  }
  function getAllowedFile(tipoAcept: string) {
    switch (tipoAcept) {
      case 'imagen':
        return ['jpg', 'jpeg', 'png', 'gif'];
      case 'file':
        return ['xls', 'xlsx'];
      default:
        return [];
    }
  }
  function getMaxWidth(ancho: number) {
    if (ancho !== undefined) return ancho;
    else return 800;
  }
  function getMaxHeight(alto: number) {
    if (ancho !== undefined) return alto;
    else return 600;
  }
  function mostrarBotonPrueba(origen: string) {
    switch (origen.toLowerCase()) {
      case 'importar':
        return false;
      default:
        return true;
    }
  }
  function initialPreview(image: { url: string }[]) {
    if (Array.isArray(image)) return image?.map((value, key) => value.url);
    else return [];
  }
  function initialPreviewConfig(image: { url: string }[]) {
    if (Array.isArray(image))
      return image?.map((value, key) => {
        let valor = value.url.split('/').at(-1);
        return {
          caption: valor,
          size: false,
          key: key,
          extra: { id: valor },
          showZoom: false,
          showUpload: false,
          showRotate: false,
          showCancel: false,
        };
      });
    else return [];
  }
  useEffect(() => {
    if (refInput.current) {
      let fileInput: any = $(refInput.current);
      fileInput
        .fileinput({
          uploadUrl: `${REACT_APP_URL_BLUEHOST}postfoto`,
          deleteUrl: `${REACT_APP_URL_BLUEHOST}dummy`,
          allowedFileExtensions: getAllowedFile(tipoAceptar),
          initialPreview: initialPreview(images),
          initialPreviewAsData: true,
          initialPreviewFileType: 'image',
          dropZoneTitle: titulo,
          initialPreviewConfig: initialPreviewConfig(images),
          uploadAsync: true,
          showPreview: true,
          showCaption: false,
          showDownload: false,
          showBrowse: false,
          overwriteInitial: false,
          maxImageWidth: getMaxWidth(ancho),
          maxImageHeight: getMaxHeight(alto),
          showCancel: false,
          showRemove: false,
          showUpload: false,
          showRotate: false,
        })
        .on('fileuploaded', (event: any, previewId: any, index: any, fileId: any, data: any) => {
          var list = images.length > 0 ? images : [];
          var res = previewId.response.initialPreviewConfig;
          if (Object.keys(res).length > 0 && !list.some((value) => value.path === res.url)) list.push({ path: res.url, url: res.downloadUrl });
          onFileUploaded(list);
        })
        .on('filebatchselected', (event: any, files: any) => {
          let current: any = $('#' + event.currentTarget.id);
          current.fileinput('upload');
          onCheckLenght(files);
        })
        .on('filedeleted', function (event: any, key: any, jqXHR: any, data: any) {
          if (images.length > 0) {
            if (images[key]?.url === `${REACT_APP_URL_BLUEHOST}uploads/${data.id}`) {
              images.splice(key, 1);
            }
          }
          deleteImages('json_imagenes', JSON.stringify(images));
        })
        .on('filecleared', function (event: any, key: any, jqXHR: any, data: any) {
          deleteImages('json_imagenes', '[]');
        });
    }
  }, [origen]);
  return (
    <div>
      {Buttons && Buttons()}
      <input ref={refInput} multiple id={`input-${origen}`} name='file_data' type='file' className={getTipoClass(clase)} data-browse-on-zone-click='true' accept={getTipoAccept(tipoAceptar)} disabled={disable}></input>
    </div>
  );
};
export default EurekaUploadFile;
