import axios from 'axios';
import { Loading } from 'notiflix';
const { REACT_APP_API_URL_2 } = process.env;

export const GetEureka = (path: string) => {
  Loading.circle();
  var url = REACT_APP_API_URL_2 + path + '?token=' + localStorage.getItem('token');
  return axios
    .get(url)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => Loading.remove());
};
export const PostEureka = (path: string, body: any) => {
  Loading.circle();
  var url = REACT_APP_API_URL_2 + path + '?token=' + localStorage.getItem('token');
  return axios
    .post(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => Loading.remove());
};
export const PutEureka = (path: string, body: any) => {
  Loading.circle();
  var url = REACT_APP_API_URL_2 + path + '?token=' + localStorage.getItem('token');
  return axios
    .put(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => Loading.remove());
};
export const DeleteEureka = (path: string, body: any) => {
  Loading.circle();
  var url = REACT_APP_API_URL_2 + path + '?token=' + localStorage.getItem('token');
  return axios
    .delete(url, body)
    .then((response) => {
      return response.data;
    })
    .catch((error) => {
      console.log(error);
      throw error;
    })
    .finally(() => Loading.remove());
};
export const ChangeUrlImage = (url: string): string => {
  const { REACT_APP_URL_BLUEHOST } = process.env;
  return url.replace('../', REACT_APP_URL_BLUEHOST + '/');
};
const EurekaGeneral = {
  EurekaLoad(accion: boolean) {
    accion ? Loading.circle() : Loading.remove();
  },
  VerificarDivisibilidad(numero: number): number {
    if (numero >= 1 && numero <= 6) {
      return numero;
    } else if (numero >= 7) {
      return 6;
    } else return 0;
  },
  tamanio_modal_tablero(chequeosObservados: any[]): string {
    const columnCount: number = EurekaGeneral.VerificarDivisibilidad(chequeosObservados.length);
    if (columnCount >= 5) {
      return 'xl';
    } else if (columnCount > 3) {
      return 'lg';
    } else if (columnCount <= 2) {
      return 'xs';
    } else {
      return 'lg';
    }
  },
};
export default EurekaGeneral;
