import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment-timezone';
import { fechaMoment, fechaSelect } from '../../helpers/index';

interface IFiltrosOrdenes {
  estadoOrdenes: IEstadoOrdenes;
  formDisabled?: boolean;
  close: Function;
  setRecargaGridOrdenes: Function;
}

interface IEstadoOrdenes {
  show: boolean;
  id: number;
  accion: string;
}

interface IUseForm {
  idorden: number;
  idchofer: { value: number; label: string; name: string };
  idestado: { value: number; label: string; name: string };
  nroorden: string;
  producto: string;
  fecha_inicio: string;
  fecha_fin: string;
}

const FiltrosOrdenes: React.FC<IFiltrosOrdenes> = ({ estadoOrdenes, formDisabled, close, setRecargaGridOrdenes }) => {
  const [selectKey1, setSelectKey1] = useState(6);
  const [selectKey2, setSelectKey2] = useState(8);
  const [selectKey3, setSelectKey3] = useState(10);
  const localFiltros = localStorage.getItem('filtrosordenes')?.length ? JSON.parse(localStorage.getItem('filtrosordenes') ?? '') : null;
  const [combos, setCombos] = useState({
    estados: [],
    choferes: [],
  });
  const { register, handleSubmit, setValue, getValues, reset } = useForm<IUseForm>({
    defaultValues: {
      idorden: 0,
      idchofer: { value: 0, label: 'Select...', name: 'idchofer' },
      idestado: { value: 0, label: 'Select...', name: 'idestado' },
      nroorden: '',
      producto: '',
      fecha_inicio: '',
      fecha_fin: '',
    },
  });
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosordenes')?.length ? JSON.parse(localStorage.getItem('filtrosordenes') ?? '') : null;
    if (filtros) {
      for (let prop in filtros) {
        if (prop === ('idorden' || 'nroorden' || 'producto' || 'fecha_inicio' || 'fecha_fin' || 'idchofer' || 'idestado')) setValue(prop, filtros[`${prop}`]);
      }
    }
    combosOrdenes(estadoOrdenes.id, estadoOrdenes.accion);
  }, [estadoOrdenes.accion, estadoOrdenes.id, setValue]);
  const combosOrdenes = (registro: number, accion: string) => {
    GetEureka('/ordenes/combos').then((response) => {
      if (response.info) {
        var registro = response.content;
        setCombos({
          estados: registro.estados,
          choferes: registro.choferes,
        });
      } else {
        console.log(response);
      }
    });
  };

  function changeValues(e: any) {
    if (e?.target?.name === 'fecha_fin' || e?.target?.name === 'fecha_inicio') {
      setValue(e.target.name, e.target.value);
      reset({ ...getValues(), [e.target.name]: e.target.value });
    } else {
      setValue(e.name, e.value);
      reset({ ...getValues(), [e.name]: e });
    }
  }

  function filterArticles(e: IUseForm) {
    for (let prop in e) {
      if (prop === 'fecha_inicio' && !e[`${prop}`].length) e[`${prop}`] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
      else if (prop === 'fecha_fin' && !e[`${prop}`].length) e[`${prop}`] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
      else if (prop === 'fecha_fin' && e[`${prop}`].length) e[`${prop}`] = fechaMoment(e[`${prop}`]);
      else if (prop === 'fecha_inicio' && e[`${prop}`].length) e[`${prop}`] = fechaMoment(e[`${prop}`]);
    }
    localStorage.setItem('filtrosordenes', JSON.stringify(e));
    setRecargaGridOrdenes(moment().format('YYYY-MM-DD HH:mm:ss'));
    close();
  }
  const resetearFiltros = (e: React.MouseEvent<HTMLButtonElement>, origen: string) => {
    e.preventDefault();
    let reseteo: any = {
      producto: '',
      nroorden: '',
      fecha_inicio: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_fin: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      idorden: 0,
      idchofer: { value: 0, label: 'Select...', name: 'idchofer' },
      idestado: { value: 0, label: 'Select...', name: 'idestado' },
    };
    localStorage.setItem('filtros' + origen, JSON.stringify(reseteo));
    for (let i in reset) {
      if (i === ('fecha_fin' || 'idorden' || 'idchofer' || 'idestado' || 'nroorden' || 'producto' || 'fecha_inicio')) setValue(i, reseteo[`${i}`]);
    }
    reset({
      producto: '',
      nroorden: '',
      fecha_inicio: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_fin: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      idorden: 0,
      idchofer: { value: 0, label: 'Select...', name: 'idchofer' },
      idestado: { value: 0, label: 'Select...', name: 'idestado' },
    });
    setSelectKey1(selectKey1 + 1);
    setSelectKey2(selectKey2 + 1);
    setSelectKey3(selectKey3 + 1);
  };

  const devolverDefault = (valor: { value: number; label: string; name: string }, select: string) => {
    switch (select) {
      case 'estados':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idestado' };
      case 'choferes':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idchofer' };
      default:
        return valor ? valor : { value: 0, label: 'Select...', name: 'idchofer' };
    }
  };

  const cerrar = (e: any) => {
    e.preventDefault();
    close();
  };

  return (
    <div className='d-flex flex-column justify-content-between height-100'>
      <form className='row height-100' onSubmit={handleSubmit(filterArticles)}>
        <div className='col-md-12'>
          <div className='row'>
            <div className='col-12'>
              <Form.Label htmlFor='tipo'>Numero de orden</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('nroorden')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='tipo'>Producto</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('producto')} />
            </div>
          </div>
          <div className='col-12'>
            <Form.Label htmlFor='articulo'>Estados</Form.Label>
            <Select
              key={selectKey1}
              defaultValue={devolverDefault(localFiltros?.idestado, 'estados')}
              options={[
                { value: 0, label: 'Seleccionar', name: 'idestado' },
                ...combos.estados.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idestado',
                })),
              ]}
              onChange={changeValues}
            />
            <Form.Label htmlFor='articulo'>Choferes</Form.Label>
            <Select
              key={selectKey2}
              defaultValue={devolverDefault(localFiltros?.idchofer, 'choferes')}
              options={[
                { value: 0, label: 'Seleccionar', name: 'idchofer' },
                ...combos.choferes.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idchofer',
                })),
              ]}
              onChange={changeValues}
            />
          </div>
          <div className='col-12'>
            <label id='fecha-desde-tablero'>Fecha inicio</label>
            <Form.Control type='date' id='fecha-desde-form' className='color-date-flexit' onChange={changeValues} name='fecha_inicio' max={fechaSelect(getValues().fecha_fin || moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'))} value={fechaSelect(getValues().fecha_inicio)} />
            <label id='fecha-desde-tablero'>Fecha fin</label>
            <Form.Control type='date' id='fecha-desde-form' className='color-date-flexit' onChange={changeValues} name='fecha_fin' min={fechaSelect(getValues().fecha_inicio || moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('DD/MM/YYYY HH:mm:ss'))} max={moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD')} value={fechaSelect(getValues().fecha_fin)} />
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-end botones-offcanvas'>
          <button className='btn background-roj btn-sm' onClick={cerrar}>
            Cerrar
          </button>
          <button className='btn background-ama btn-sm' onClick={(e) => resetearFiltros(e, 'ordenes')}>
            Resetear
          </button>
          <button type='submit' className='btn background-ver btn-sm'>
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
};
export default FiltrosOrdenes;
