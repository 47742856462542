import React, { useState } from 'react';
import { Card } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import FormEntregas from './Form-Entregas';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltrosEntregas from './Filtros-Entregas';
import Notiflix from 'notiflix';
import { PutEureka } from '../estructura/EurekaGeneral/EurekaGeneral';
import moment from 'moment-timezone';

function Entregas() {
  const [modalEntregas, setmodalEntregas] = useState({ show: false, id: 0, accion: 'a' });
  const ModalClose = () => setmodalEntregas({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalEntregas({ show: true, id: id, accion: accion });

  const [offcanvasEntregas, setoffcanvasEntregas] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasShow = (id: number, accion: string) => setoffcanvasEntregas({ show: true, id: id, accion: accion });

  const [offcanvasFiltrosEntregas, setoffcanvasFiltrosEntregas] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosClose = () => setoffcanvasFiltrosEntregas({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosEntregas({ show: true, id: id, accion: accion });

  const [recargaGridEntregas, setRecargaGridEntregas] = useState<string>('');
  const [formDisabled, setformDisabled] = useState<boolean>(false);

  const confirmarEntrega = (origen: string, id: number, accion: string) => {
    Notiflix.Confirm.show(
      'Desea confirmar esta entrega?',
      `Confirmar entrega N° #${id}?`,
      'Si',
      'No',
      () => {
        PutEureka(`/${origen}/confirmar/${id}`, {}).then(() => {
          setRecargaGridEntregas(moment().format('YYYY-MM-DD HH:mm:ss'));
          Notiflix.Report.success('Entrega confirmada con exito', `#${id}`, 'Cerrar');
        });
      },
      () => {}
    );
  };

  const manejo_acciones = (origen: string, registro: number, accion: string) => {
    switch (accion) {
      case 'c':
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'i':
        offCanvasShow(registro, accion);
        break;
      case 'a':
        offCanvasFiltrosShow(registro, accion);
        break;
      case 'cfm':
        confirmarEntrega(origen, registro, accion);
        break;
      default:
        return;
    }
  };
  return (
    <>
      <PageLayout label='Adm. Entregas'>
        <Card>
          <Card.Body>
            {Grid(manejo_acciones, 'entregas', recargaGridEntregas, setRecargaGridEntregas)}
            <EurekaModals id='modal_Entregas' manejador={modalEntregas} modalTitulo='Consultar entregas' sizeModal='xl' handleClose={ModalClose}>
              <FormEntregas estadoEntregas={modalEntregas} formDisabled={formDisabled} close={ModalClose}></FormEntregas>
            </EurekaModals>
            <EurekaOffcanvas id='offcanvas_filtrosentregas' manejador={offcanvasFiltrosEntregas} offcanvasTitulo='Filtros' handleClose={offCanvasFiltrosClose} posicion='start' size='w-20'>
              <FiltrosEntregas estadoEntregas={offcanvasEntregas} close={offCanvasFiltrosClose} setRecargaGridEntregas={setRecargaGridEntregas}></FiltrosEntregas>
            </EurekaOffcanvas>
          </Card.Body>
        </Card>
      </PageLayout>
    </>
  );
}
export default Entregas;
