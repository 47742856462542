import { useCallback, useEffect } from 'react';
import { useForm } from 'react-hook-form';
import { Table, Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment';

interface IFormOrdenes {
  estadoOrdenes: IEstadoOrdenes;
  formDisabled?: boolean;
  close: Function;
}

interface IEstadoOrdenes {
  show: boolean;
  id: number;
  accion: string;
}

interface IUseForm {
  chofer: string;
  nroorden: string;
  estado: string;
  fecha_alta: string;
  productos: { articulo: string; sku: string; cantidad: number }[];
}

const FormOrdenes: React.FC<IFormOrdenes> = ({ estadoOrdenes, formDisabled, close }) => {
  const { register, setValue, getValues, reset } = useForm<IUseForm>({
    defaultValues: {
      chofer: '',
      nroorden: '',
      estado: '',
      fecha_alta: '',
      productos: [],
    },
  });
  const articulobyid = useCallback(
    (registro: number, accion: string) => {
      GetEureka('/ordenes/' + registro).then((response) => {
        if (response.info) {
          var registro = response.content[0];
          setValue('chofer', registro.chofer);
          setValue('nroorden', registro.nroorden);
          setValue('estado', registro.estado);
          setValue('fecha_alta', moment(registro.fecha_alta).format('DD/MM/YYYY'));
          setValue('productos', registro.productos);
          reset({
            chofer: registro.chofer,
            nroorden: registro.nroorden,
            estado: registro.estado,
            fecha_alta: moment(registro.fecha_alta).format('DD/MM/YYYY'),
            productos: registro.productos,
          });
        } else {
          console.log(response);
        }
      });
    },
    [setValue, reset]
  );
  useEffect(() => {
    if (estadoOrdenes.show) articulobyid(estadoOrdenes.id, estadoOrdenes.accion);
  }, [articulobyid, estadoOrdenes.accion, estadoOrdenes.id]);
  function cerrar(e: React.MouseEvent<HTMLButtonElement>) {
    e.preventDefault();
    close();
  }
  return (
    <>
      <form className='row'>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='chofer'>Chofer</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('chofer')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='nroorden'>Numero de orden</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('nroorden')} />
            </div>
          </div>
        </div>
        <div className='col-md-6'>
          <div>
            <div className='col-12'>
              <Form.Label htmlFor='estado'>Estado</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('estado')} />
            </div>
            <div className='col-12'>
              <Form.Label htmlFor='fecha_alta'>Fecha de alta</Form.Label>
              <Form.Control type='text' disabled={formDisabled} {...register('fecha_alta')} />
            </div>
          </div>
        </div>
        <div className='col-md-12'>
          <Form.Label htmlFor='productos'>Productos</Form.Label>
          <div className='lineaTabla'></div>
          <Table bordered hover size='sm' className='tablaReact w-100'>
            <thead>
              <tr>
                <th className='colorLetra'>Descripcion</th>
                <th className='colorLetra'>Sku</th>
                <th className='colorLetra'>Cantidad</th>
              </tr>
            </thead>
            <tbody>
              {getValues()?.productos?.map((produc, key) => {
                return (
                  <tr key={key}>
                    <td>
                      <p className='colorLetra f-13'>{produc.articulo}</p>
                    </td>
                    <td>
                      <p className='colorLetra f-13'>{produc.sku}</p>
                    </td>
                    <td>
                      <p className='colorLetra f-13'>{produc.cantidad}</p>
                    </td>
                  </tr>
                );
              })}
            </tbody>
          </Table>
        </div>
        <div className='d-flex justify-content-end'>
          <button className='btn btn-sm background-roj' onClick={cerrar}>
            Cerrar
          </button>
        </div>
      </form>
    </>
  );
};
export default FormOrdenes;
