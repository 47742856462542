import CardHeader from './CardHeader';
import { List, Item, Heading, Text, Box, Icon } from '../elements';

interface ICountriesCard {
  title: string;
  dotsMenu?: IDostMenu;
  items?: { producto: string; stock: number }[];
}

interface IDostMenu {
  dots: string;
  dropdown: {
    icon: string;
    text: string;
  }[];
}

const CountriesCard: React.FC<ICountriesCard> = ({ title, dotsMenu, items }) => {
  return (
    <Box className='mc-card'>
      <CardHeader title={title} dotsMenu={dotsMenu} />
      <List className='mc-countries-card-list thin-scrolling'>
        <Item className='mc-countries-card-item'>
          <Heading as='h6'>
            <Text as='span'>{'Producto'}</Text>
          </Heading>
          <Heading as='h6'>
            <Text as='span'>{'Cantidad'}</Text>
          </Heading>
        </Item>
        {items?.length ? (
          <>
            {items?.map((item, index) => (
              <Item key={index} className='mb-0 mc-countries-card-item'>
                <Heading as='h6'>{item.producto}</Heading>
                <Heading as='h6'>{item.stock}</Heading>
              </Item>
            ))}
          </>
        ) : (
          <div className='w-100 h-100 d-flex justify-content-center align-items-center'>
            <Icon type={'done'} style={{ fontSize: '80px' }} />
          </div>
        )}
      </List>
    </Box>
  );
};

export default CountriesCard;
