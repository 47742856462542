import { Badge } from 'react-bootstrap';

interface IEurekaOrdenesFinalizadas {
  finalizadas: IFinalizada[];
  SetRefresh: Function;
  direction: boolean;
  hidden?: boolean;
}

interface IFinalizada {
  estado: string;
  nro_venta: string;
  nroorden: string;
  chofer: string;
  sucursal: string;
  total: number;
  idorden: number;
  idestado: number;
}

const EurekaOrdenesFinalizadas: React.FC<IEurekaOrdenesFinalizadas> = ({ finalizadas = [], SetRefresh, direction = true }) => {
  return (
    <div className={direction ? 'col-md-12 mt-4' : 'col-md-4'}>
      <>
        {/*                 <EurekaModals manejador={modalPendiente} modalTitulo={modalTitulo} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosfinalizadas)} handleClose={ModalClose}>
                    {
                        modalPendiente.tipo === 1 ? (<FormCanceladosEntregas ListaChecks={chequeosfinalizadas} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                            modalPendiente.tipo === 2 ? (<FormTrackingEntregas identrega={identregaActivo} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                                modalPendiente.tipo === 3 ? (<FormConfirmadosEntregas ListaChecks={chequeosfinalizadas} identrega={identregaActivo} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                                    modalPendiente.tipo === 4 ? (<FormImprimirEntregas ListaChecks={chequeosfinalizadas} identrega={identregaActivo} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                                        modalPendiente.tipo === 5 ? (<FormQrEntregas identrega={entregaActivo.identrega} />) :
                                            (<div className="alert alert-danger" role="alert">No hay entregas seleccionadas</div>)
                    }
                </EurekaModals> */}
        <div>
          <div className='card col_entregas_finalizadas'>
            <div className='card-header eureka-bg-success'>
              <div className='row'>
                <div className='col-10 text-success'>
                  Finalizadas <Badge bg='success'>{finalizadas.length}</Badge>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className={`accordion ${direction ? 'row' : ''}`} id='accordionfinalizadas'>
                {finalizadas.map((finalizada, key) => (
                  <div className={`accordion-item ${direction ? 'col-md-4' : ''}`} key={key}>
                    <div className={`accordion-header ${finalizada.idestado === 5 ? 'eureka-bg-success' : 'eureka-bg-danger'} accordion-finalizado`}>
                      <nav className='navbar bg-body-tertiary'>
                        <div className='container-fluid'>
                          <div className='form-check'>
                            <label className={`${finalizada.idestado === 5 ? 'text-success' : 'text-danger'} form-check-label f-13`} data-bs-toggle='collapse' data-bs-target={'#collapse_fin_' + key} aria-expanded='false' aria-controls={'collapse_fin_' + key}>
                              #{finalizada.nroorden} - {finalizada.estado}
                            </label>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div id={'collapse_fin_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionfinalizadas'>
                      <div className='text-dark accordion-body'>
                        {/* <small>Origen: {pendiente.nro_venta}</small><br /> */}
                        <small>Chofer: {finalizada.chofer}</small>
                        <br />
                        <small>Sucursal: {finalizada.sucursal}</small>
                        <br />
                        <small>Cantidad: {finalizada.total}</small>
                        <br />
                        {/* <Button size="sm pt-0 pb-0 w-100" variant="primary">Modificar</Button> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EurekaOrdenesFinalizadas;
