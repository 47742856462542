import React from 'react';
import Icon from './elements/Icon.tsx';
import { Dropdown } from 'react-bootstrap';
import DropdownMenu from './DropdownMenu.tsx';

interface IDostMenu {
  dots: string;
  dropdown?: {
    icon: string;
    text: string;
  }[];
  onClick: React.MouseEventHandler<HTMLAnchorElement>;
}

const DotsMenu: React.FC<IDostMenu> = ({ dots, dropdown, onClick }) => {
  return (
    <Dropdown bsPrefix='mc-dropdown'>
      <Dropdown.Toggle bsPrefix='mc-dropdown-toggle'>
        <Icon type={dots} />
      </Dropdown.Toggle>
      <DropdownMenu dropdown={dropdown} onClick={onClick} />
    </Dropdown>
  );
};

export default DotsMenu;
