import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import moment from 'moment-timezone';
import { fechaMoment, fechaSelect } from '../../helpers/index';

interface IFiltrosCCorriente {
  estadoCcorriente: { show: boolean; id: number; accion: string };
  close: Function;
  setRecargaGridCcorriente: Function;
}

interface IUseForm {
  idorigen: ILabel;
  idtipomovimiento: ILabel;
  idtipo: ILabel;
  idccorriente: number;
  detallemovimiento: string;
  fecha_inicio: string;
  fecha_fin: string;
}

interface ILabel {
  value: number;
  label: string;
  name: string;
}

const FiltrosCcorriente: React.FC<IFiltrosCCorriente> = ({ estadoCcorriente, close, setRecargaGridCcorriente }) => {
  const [selectKey1, setSelectKey1] = useState(6);
  const [selectKey2, setSelectKey2] = useState(8);
  const [selectKey3, setSelectKey3] = useState(10);
  const localFiltros = JSON.parse(localStorage.getItem('filtrosccorriente') ?? '{}');
  const [combos, setCombos] = useState({
    origen: [],
    tipo: [],
    tipomovimiento: [],
  });
  const { handleSubmit, setValue, getValues, reset } = useForm<IUseForm>({
    defaultValues: {
      idorigen: { value: 0, label: 'Select...', name: 'idorigen' },
      idtipomovimiento: {
        value: 0,
        label: 'Select...',
        name: 'idtipomovimiento',
      },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
      idccorriente: 0,
      detallemovimiento: '',
      fecha_inicio: '',
      fecha_fin: '',
    },
  });
  useEffect(() => {
    let filtros = localStorage.getItem('filtrosccorriente')?.length ? JSON.parse(localStorage.getItem('filtrosccorriente') ?? '{}') : null;
    if (filtros) {
      for (let prop in filtros) {
        if (prop === ('idccorriente' || 'detallemovimiento' || 'fecha_inicio' || 'fecha_fin' || 'idorigen' || 'idtipomovimiento' || 'idtipo')) setValue(prop, filtros[`${prop}`]);
      }
    }
    combosCcorriente(estadoCcorriente.id, estadoCcorriente.accion);
  }, [estadoCcorriente.accion, estadoCcorriente.id, setValue]);
  const combosCcorriente = (registro: number, accion: string) => {
    GetEureka('/Ccorriente/combos').then((response) => {
      if (response.info) {
        var registro = response.content;
        setCombos({
          origen: registro.origen,
          tipo: registro.tipo,
          tipomovimiento: registro.tipomovimiento,
        });
      } else {
        console.log(response);
      }
    });
  };

  function changeValues(e: any) {
    if (e?.target?.name === 'fecha_fin' || e?.target?.name === 'fecha_inicio') {
      setValue(e.target.name, e.target.value);
      reset({ ...getValues(), [`${e.target.name}`]: `${e.target.value}` });
    } else {
      setValue(e.name, e.value);
      reset({ ...getValues(), [`${e.name}`]: e });
    }
  }

  function filterArticles(e: IUseForm) {
    for (let prop in e) {
      if (prop === 'fecha_inicio' && !e[`${prop}`].length) e[`${prop}`] = moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
      else if (prop === 'fecha_fin' && !e[`${prop}`].length) e[`${prop}`] = moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss');
      else if (prop === 'fecha_fin' && e[`${prop}`].length) e[`${prop}`] = fechaMoment(e[`${prop}`]);
      else if (prop === 'fecha_inicio' && e[`${prop}`].length) e[`${prop}`] = fechaMoment(e[`${prop}`]);
    }
    localStorage.setItem('filtrosccorriente', JSON.stringify(e));
    setRecargaGridCcorriente(moment().format('YYYY-MM-DD HH:mm:ss'));
    close();
  }
  const resetearFiltros = (e: React.MouseEvent<HTMLButtonElement>, origen: string) => {
    e.preventDefault();
    let reseteo: any = {
      idccorriente: 0,
      detallemovimiento: '',
      fecha_inicio: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_fin: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      idorigen: { value: 0, label: 'Select...', name: 'idorigen' },
      idtipomovimiento: {
        value: 0,
        label: 'Select...',
        name: 'idtipomovimiento',
      },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    };
    localStorage.setItem('filtros' + origen, JSON.stringify(reseteo));
    for (let i in reset) {
      if (i === ('fecha_fin' || 'idccorriente' || 'detallemovimiento' || 'fecha_inicio' || 'idorigen' || 'idtipomovimiento' || 'idtipo')) setValue(i, reseteo[`${i}`]);
    }
    reset({
      idccorriente: 0,
      detallemovimiento: '',
      fecha_inicio: moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      fecha_fin: moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'),
      idorigen: { value: 0, label: 'Select...', name: 'idorigen' },
      idtipomovimiento: {
        value: 0,
        label: 'Select...',
        name: 'idtipomovimiento',
      },
      idtipo: { value: 0, label: 'Select...', name: 'idtipo' },
    });
    setSelectKey1(selectKey1 + 1);
    setSelectKey2(selectKey2 + 1);
    setSelectKey3(selectKey3 + 1);
  };

  const devolverDefault = (valor: { value: number; label: string; name: string }, select: string) => {
    switch (select) {
      case 'estados':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idestado' };
      case 'choferes':
        return valor ? valor : { value: 0, label: 'Select...', name: 'idchofer' };
      default:
        return valor ? valor : { value: 0, label: 'Select...', name: 'idchofer' };
    }
  };

  const cerrar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    close();
  };

  return (
    <div className='d-flex flex-column justify-content-between height-100'>
      <form className='row height-100' onSubmit={handleSubmit(filterArticles)}>
        <div className='col-md-12'>
          <div className='col-12'>
            <Form.Label htmlFor='ccorriente'>Origen</Form.Label>
            <Select
              key={selectKey1}
              defaultValue={devolverDefault(localFiltros?.idorigen, 'origen')}
              options={[
                { value: 0, label: 'Select...', name: 'idorigen' },
                ...combos.origen.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idorigen',
                })),
              ]}
              onChange={changeValues}
            />
            <Form.Label htmlFor='ccorriente'>Tipo de movimiento</Form.Label>
            <Select
              key={selectKey2}
              defaultValue={devolverDefault(localFiltros?.idtipomovimiento, 'tipo_movimiento')}
              options={[
                { value: 0, label: 'Select...', name: 'idtipomovimiento' },
                ...combos.tipomovimiento.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idtipomovimiento',
                })),
              ]}
              onChange={changeValues}
            />
            <Form.Label htmlFor='ccorriente'>Tipo</Form.Label>
            <Select
              key={selectKey3}
              defaultValue={devolverDefault(localFiltros?.idtipo, 'tipos')}
              options={[
                { value: 0, label: 'Select...', name: 'idtipo' },
                ...combos.tipo.map((marca: any) => ({
                  value: marca.id,
                  label: marca.descripcion,
                  name: 'idtipo',
                })),
              ]}
              onChange={changeValues}
            />
          </div>
          <div className='col-12'>
            <label id='fecha-desde-tablero'>Fecha inicio</label>
            <Form.Control type='date' id='fecha-desde-form' className='color-date-flexit' onChange={changeValues} name='fecha_inicio' max={fechaSelect(getValues().fecha_fin || moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD HH:mm:ss'))} value={fechaSelect(getValues().fecha_inicio || '')} />
            <label id='fecha-desde-tablero'>Fecha fin</label>
            <Form.Control type='date' id='fecha-desde-form' className='color-date-flexit' onChange={changeValues} name='fecha_fin' min={fechaSelect(getValues().fecha_inicio || moment().subtract(2, 'weeks').tz('America/Argentina/Buenos_Aires').format('DD/MM/YYYY HH:mm:ss'))} max={moment().tz('America/Argentina/Buenos_Aires').format('YYYY-MM-DD')} value={fechaSelect(getValues().fecha_fin || '')} />
          </div>
        </div>
        <div className='d-flex justify-content-end align-items-end botones-offcanvas'>
          <button className='btn background-roj btn-sm' onClick={cerrar}>
            Cerrar
          </button>
          <button className='btn background-ama btn-sm' onClick={(e) => resetearFiltros(e, 'ccorriente')}>
            Resetear
          </button>
          <button type='submit' className='btn background-ver btn-sm'>
            Buscar
          </button>
        </div>
      </form>
    </div>
  );
};
export default FiltrosCcorriente;
