import { ThemeProvider } from './context/Themes.tsx';
import { LoaderProvider } from './context/Preloader.tsx';
import { HashRouter, Routes, Route } from 'react-router-dom';
import { Error } from './pages/supports/index.ts';
import { Analytics, ForgotPassword, Login, MyAccount, Notification } from './pages/master/index.ts';
import './css/bootstrap.min.css';
import './css/styles.css';
import './css/icofont/icofont.css';
import './css/icon.css';
import './css/text.css';
import Articulos from './components-new/articulos/Articulos';
import Ordenes from './components-new/ordenes/Ordenes';
import CCorriente from './components-new/ccorriente/Ccorriente';
import Entregas from './components-new/entregas/Entregas';
import Ventaconsignacion from './components-new/ventaconsignacion/Ventaconsignacion';
import TableroEntregas from './components-new/EurekaTablero/TableroEntregas/TableroEntregas';
import TableroOrdenes from './components-new/EurekaTablero/TableroOrdenes/TableroOrdenes';
import EurekaRedireccion from './components-new/estructura/EurekaRedireccion/EurekaRedireccion';
import { FlexitProvider } from './context/Flexit.tsx';

let titulo = document.title;

window.addEventListener(
  'blur',
  () => {
    titulo = document.title;
    document.title = 'Flexit te espera';
  },
  { passive: true }
);
window.addEventListener(
  'focus',
  () => {
    document.title = titulo;
  },
  { passive: true }
);

export default function App() {
  return (
    <ThemeProvider>
      <FlexitProvider>
        <LoaderProvider>
          <HashRouter>
            <Routes>
              <Route path='/notification' element={<EurekaRedireccion path='/notification' element={<Notification />} />} />
              <Route path='/Dashboard' element={<EurekaRedireccion path='/dashboard' element={<Analytics />} />} />
              <Route path='/' element={<EurekaRedireccion path='/' element={<Login />} />} />
              <Route path='/login' element={<EurekaRedireccion path='/login' element={<Login />} />} />
              <Route path='/CambiarContraseña' element={<EurekaRedireccion path='/CambiarContraseña' element={<ForgotPassword />} />} />
              <Route path='/MiPerfil' element={<EurekaRedireccion path='/MiPerfil' element={<MyAccount />} />} />
              <Route path='/Entregas/Tablero' element={<EurekaRedireccion path='/Entregas/Tablero' element={<TableroEntregas />} />} />
              <Route path='/Articulos' element={<EurekaRedireccion path='/Articulos' element={<Articulos />} />} />
              <Route path='/Entregas/Grid' element={<EurekaRedireccion path='/Entregas/Grid' element={<Entregas />} />} />
              <Route path='/Entregas' element={<EurekaRedireccion path='/Entregas' element={<TableroEntregas />} />} />
              <Route path='/Ordenes/Grid' element={<EurekaRedireccion path='/Ordenes/Grid' element={<Ordenes />} />} />
              <Route path='/Ordenes/Tablero' element={<EurekaRedireccion path='/Ordenes/Tablero' element={<TableroOrdenes />} />} />
              <Route path='/Ordenes' element={<EurekaRedireccion path='/Ordenes' element={<TableroOrdenes />} />} />
              <Route path='/CCorriente' element={<EurekaRedireccion path='/CCorriente' element={<CCorriente />} />} />
              <Route path='/VentaConsignacion' element={<EurekaRedireccion path='/VentaConsignacion' element={<Ventaconsignacion />} />} />
              {/*             <Route path="/Auditoria" element={<Menu />} />
            <Route path="/Stock" element={<BlankPage />} />
            <Route path="/Tienda" element={<CrearTienda />} />
            <Route path="/Tienda/:nombre" element={<BlankPage />} />
            <Route path="/Producto" element={<ProductView />} /> */}
              <Route path='*' element={<Error />} />
            </Routes>
          </HashRouter>
        </LoaderProvider>
      </FlexitProvider>
    </ThemeProvider>
  );
}
