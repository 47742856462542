import React, { useEffect, useState } from 'react';
import useGetComplexObject from '../../hooks/useGetComplexObject';
import data from '../../data/master/analytics.json';
import dataEco from '../../data/master/ecommerce.json';
import dataProfi from '../../data/master/userProfile.json';
import PageLayout from '../../layouts/PageLayout';
import { Row, Col } from 'react-bootstrap';
import { recortarFecha, cambioFormatoFecha } from '../../helpers';
import { RoundAvatar, DuelText } from '../../components';
import { Box } from '../../components/elements';
import { FlexitContext } from '../../context/Flexit';
import { DevicesCard, CountriesCard, CardLayout, FloatCard, RevenueCard } from '../../components/cards';
import { useNavigate } from 'react-router-dom';
import PlaceholderDashboard from '../../components/placeholders/PlaceholderDashboard';
import capitalize from 'capitalize';
import { GetEureka } from '../../components-new/estructura/EurekaGeneral/EurekaGeneral';

interface IResponse {
  info: boolean;
  msg: string;
  content: IDashboard;
}

interface IDashboard {
  ccorriente_diario: IDiario[];
  conteo_diario: IConteoDiario;
  conteo_mensual: { consignacion: number; entregas: number; ordenes: number; saldoactual: number };
  productos: IProducto[];
  saldo_actual: number;
  usuario: IUsuario;
}

interface IConteoDiario {
  [key: string]: any;
  consignacion: IDiario[];
  entregas: IDiario[];
  ordenes: IDiario[];
}

interface IDiario {
  fecha: string;
  cantidad: number;
}

interface IProducto {
  idproducto: number;
  producto: string;
  stock: number;
}

interface IUsuario {
  apellidos: string;
  idusuario: number;
  imagen: string;
  nombres: string;
  timezone: string;
  username: string;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

export default function Analytics() {
  const { datos } = useGetComplexObject<FlexitContextType>(FlexitContext);
  const [loading, setLoading] = useState(false);
  const [dashboard, setDashboard] = useState<IDashboard>();
  const [imageLoaded, setImageLoaded] = useState(false);
  const [imageSrc, setImageSrc] = useState('');
  const navigate = useNavigate();
  const redireccionGrid = (item: string) => {
    navigate(item === 'cuenta corriente' ? '/CCorriente' : `/${capitalize(item)}/Grid`);
  };

  const cambioDia = (e: React.ChangeEvent<HTMLSelectElement>) => {
    getDashboard(parseInt(e.target.value));
  };

  async function getDashboard(dias: number) {
    setLoading(false);
    const respuesta: IResponse = await GetEureka(`/Dashboard/${dias}`);
    let objeto: IConteoDiario = respuesta.content.conteo_diario;
    const newArray: any = [];
    for (let i in objeto) {
      objeto[`${i}`]?.map(
        (value: any, key: number) =>
          (newArray[`${key}`] = {
            ...newArray[`${key}`],
            month: recortarFecha(value.fecha),
            [`${i}`]: value.cantidad,
          })
      );
    }
    setDashboard({
      ...respuesta.content,
      conteo_diario: newArray,
    });
    setLoading(true);
  }

  useEffect(() => {
    if (localStorage.getItem('token')) getDashboard(7);
    localStorage.removeItem('activar');
  }, []);
  useEffect(() => {
    const defaultImage = 'https://cdn-icons-png.flaticon.com/512/3033/3033143.png';
    const userImage = datos?.usuario?.imagen;
    const imageToLoad = userImage === '../assets/images/logo.jpg' ? defaultImage : userImage;

    if (imageToLoad) {
      const img = new Image();
      img.src = imageToLoad;
      img.onload = () => {
        setImageSrc(imageToLoad);
        setImageLoaded(true);
      };
    }
  }, [datos]);

  return (
    <>
      {loading ? (
        <PageLayout label='Dashboard principal'>
          <Row>
            <Col lg={4}>
              <CardLayout>
                <Box className='mc-user-group'>
                  <Box className='mc-user-profile'>
                    {!imageLoaded ? (
                      <div className='spinner-border' role='status'>
                        <span className='visually-hidden'>Loading...</span>
                      </div>
                    ) : (
                      <RoundAvatar src={imageLoaded ? imageSrc : datos?.usuario?.imagen} alt={'Avatar'} size={dataProfi?.profile.size} />
                    )}
                    <DuelText title={`${datos?.usuario?.username}`} descrip={`@${datos?.usuario?.nombres} ${datos?.usuario?.apellidos}`} size={dataProfi?.profile.size} />
                    <Col xs={12} mt={5}>
                      {dataProfi?.float
                        ?.map((value) => {
                          if (value.title === 'ordenes en camino')
                            return {
                              ...value,
                              digit: dashboard?.conteo_mensual?.ordenes,
                            };
                          else if (value.title === 'entregas pendientes')
                            return {
                              ...value,
                              digit: dashboard?.conteo_mensual?.entregas,
                            };
                          else
                            return {
                              ...value,
                              digit: dashboard?.conteo_mensual?.saldoactual?.toFixed(2),
                            };
                        })
                        .map((item, index) => (
                          <div className='mt-2 mb-2' key={index}>
                            <Col md={12} lg={12}>
                              <FloatCard variant={item.variant} digit={item.digit} title={item.title} icon={item.icon} onClick={() => redireccionGrid(item.click)} style={{ cursor: item.cursor }} />
                            </Col>
                          </div>
                        ))}
                    </Col>
                  </Box>
                </Box>
              </CardLayout>
            </Col>
            <Col lg={8}>
              <RevenueCard
                title={'Graficos diarios'}
                field={dataEco?.revenue.field}
                onChange={cambioDia}
                chart={dashboard?.conteo_diario?.map((value: any) => ({
                  ...value,
                  month: cambioFormatoFecha(value.month),
                }))}
              />
            </Col>
            <Col lg={7}>
              <DevicesCard
                title={data?.device.title}
                chart={dashboard?.ccorriente_diario?.map((value) => ({
                  ...value,
                  fecha: cambioFormatoFecha(recortarFecha(value.fecha)),
                }))}
              />
            </Col>
            <Col xs={12} lg={5}>
              <CountriesCard title={'Productos sin stock'} items={dashboard?.productos} />
            </Col>
          </Row>
        </PageLayout>
      ) : (
        <PageLayout>
          <PlaceholderDashboard />
        </PageLayout>
      )}
    </>
  );
}
