import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faTrash, faTimeline, faLocationArrow, faQrcode } from '@fortawesome/free-solid-svg-icons';
import React, { useState } from 'react';
import { Badge, Button } from 'react-bootstrap';
import EurekaModals from '../../estructura/EurekaModals/EurekaModals';
import EurekaGeneral from '../../estructura/EurekaGeneral/EurekaGeneral';
import { FormCanceladosEntregas, cancelarEntregaUnitaria, FormTrackingEntregas, FormConfirmadosEntregas, FormImprimirEntregas, FormQrEntregas } from './Genericos/FormGenericos';
import $ from 'jquery';
const { REACT_APP_URL_BLUEHOST } = process.env;

interface IEurekaTableroPendientes {
  pendientes: IPendiente[];
  underline: React.MouseEventHandler<HTMLLabelElement>;
  noUnderline: React.MouseEventHandler<HTMLLabelElement>;
  SetRefresh: Function;
  direction: boolean;
}

interface IPendiente {
  nro_venta: string;
  identrega: number;
  fecha: string;
  codinterno: string;
  tipoconector: number;
  productos: { cantidad: number; articulo: string }[];
  direccion: string;
  telefono: number;
  comentario: string;
  observaciones: string;
}

const EurekaTableroPendientes: React.FC<IEurekaTableroPendientes> = ({ pendientes = [], underline, noUnderline, SetRefresh, direction }) => {
  const [chequeosPendientes, setChequeosPendientes] = useState([]);
  const [identregaActivo, setIdEntregaActivo] = useState<number>(0);
  const [entregaActivo, setEntregaActivo] = useState<{ identrega: number; nroventa: string }>({ identrega: 0, nroventa: '' });
  const [modalTitulo, setModalTitulo] = useState('Cancelar Entregas');
  const [modalPendiente, setmmodalPendiente] = useState({ show: false, tipo: 0 });
  const FnSetEntregaActivo = (id: number, nroventa: string) => setEntregaActivo({ identrega: id, nroventa: nroventa });
  const ModalClose = () => setmmodalPendiente({ show: false, tipo: 0 });
  const ModalShow = (tipo: number) => setmmodalPendiente({ show: true, tipo: tipo });
  function checkallpendientes() {
    if ($('.all_chk_pendientes').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  function btn_cancelar_entrega() {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]:checked').forEach((input) => {
      const id = Number(input.value);
      const nroventa = Number(input.dataset.nroventa);
      const bolsa_ = { id: id, nroventa: nroventa };
      bolsa.push(bolsa_);
    });
    setModalTitulo('Cancelar Entregas');
    setChequeosPendientes(bolsa);
    ModalShow(1);
  }
  function btn_cotizar_entrega() {
    console.log(chequeosPendientes);
  }
  function btn_imprimir_entrega() {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]:checked').forEach((input) => {
      const id = Number(input.value);
      const nroventa = Number(input.dataset.nroventa);
      const bolsa_ = { id: id, nroventa: nroventa };
      bolsa.push(bolsa_);
    });
    setModalTitulo('Imprimir Entregas');
    setChequeosPendientes(bolsa);
    ModalShow(4);
  }
  function btn_modificar_entrega() {
    console.log(chequeosPendientes);
  }
  function btn_confirmar_entrega() {
    var bolsa: any = [];
    document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]:checked').forEach((input) => {
      const id = Number(input.value);
      const nroventa = Number(input.dataset.nroventa);
      const bolsa_ = { id: id, nroventa: nroventa };
      bolsa.push(bolsa_);
    });
    setModalTitulo('Confirmar Entregas');
    setChequeosPendientes(bolsa);
    ModalShow(3);
  }
  function btn_tracking_entrega(identrega: number) {
    setModalTitulo('Tracking Entrega');
    setIdEntregaActivo(identrega);
    ModalShow(2);
  }
  function btn_qr_entrega(identrega: number) {
    setModalTitulo('QR Entrega');
    FnSetEntregaActivo(identrega, '0');
    ModalShow(5);
  }
  return (
    <>
      <EurekaModals manejador={modalPendiente} modalTitulo={modalTitulo} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosPendientes) as 'lg' | 'sm' | 'xl' | undefined} handleClose={ModalClose}>
        {modalPendiente.tipo === 1 ? (
          <FormCanceladosEntregas ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalPendiente.tipo === 2 ? (
          <FormTrackingEntregas identrega={identregaActivo} />
        ) : modalPendiente.tipo === 3 ? (
          <FormConfirmadosEntregas ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalPendiente.tipo === 4 ? (
          <FormImprimirEntregas ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />
        ) : modalPendiente.tipo === 5 ? (
          <FormQrEntregas identrega={entregaActivo.identrega} />
        ) : (
          <div className='alert alert-danger' role='alert'>
            No hay entregas seleccionadas
          </div>
        )}
      </EurekaModals>
      <div className={direction ? 'col-md-12 mt-2' : 'col-md-4'}>
        <div className='card col_entregas_pendientes'>
          <div className='card-header eureka-bg-primary'>
            <div className='row'>
              <div className='col-10 text-light'>
                <input className='all_chk_pendientes' onClick={checkallpendientes} type='checkbox' /> Pendientes <Badge bg='primary'>{pendientes.length}</Badge>
              </div>
              <div className='col-2 text-end'>
                <div className='dropdow'>
                  <button className='btn btn-primary btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                    +
                  </button>
                  <ul className='dropdown-menu'>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_cotizar_entrega}>
                        Cotizar entrega
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_imprimir_entrega}>
                        Imprimir entrega
                      </button>
                    </li>
                    <li hidden={true}>
                      <button className='btn btn-xs dropdown-item' onClick={btn_modificar_entrega}>
                        Modificar entrega
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_cancelar_entrega}>
                        Cancelar entrega
                      </button>
                    </li>
                    <li>
                      <button className='btn btn-xs dropdown-item' onClick={btn_confirmar_entrega}>
                        Confirmar entrega
                      </button>
                    </li>
                  </ul>
                </div>
              </div>
            </div>
          </div>
          <div className='card-body'>
            <div className={`accordion ${direction ? 'row' : ''}`} id='accordionPendientes'>
              {pendientes.map((pendiente, key) => (
                <div className={`accordion-item ${direction ? 'col-md-4' : ''}`} key={key}>
                  <div className='accordion-header eureka-bg-primary accordion-pendiente'>
                    <nav className='navbar bg-body-tertiary'>
                      <div className='container-fluid'>
                        <div className='form-check'>
                          <input className={'chk_p_' + key + ' form-check-input me-1'} data-nroventa={pendiente.nro_venta} type='checkbox' value={pendiente.identrega} id={'chk_pen_' + key} />
                          <button className='btn btn-danger btn-sm' onClick={(e) => cancelarEntregaUnitaria(e, setChequeosPendientes, ModalShow)} data-nroventa={pendiente.nro_venta} data-value={pendiente.identrega}>
                            <FontAwesomeIcon icon={faTrash} onClick={(e) => e.stopPropagation()} />
                          </button>
                          &nbsp;
                          <label className='text-ligth form-check-label f-13' data-bs-toggle='collapse' data-bs-target={'#collapse_pen_' + key} aria-expanded='false' aria-controls={'collapse_pen_' + key} onMouseEnter={underline} onMouseLeave={noUnderline}>
                            #{pendiente.codinterno} - {pendiente.fecha}
                          </label>
                        </div>
                        <div className='dropdown' key={key} id={`menu-pen-${key}`}>
                          <button className='btn btn-primary btn-sm dropdown-toggle button-conector' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                            {pendiente.tipoconector}
                          </button>
                          <ul className='dropdown-menu'>
                            <li>
                              <a onClick={(e) => btn_qr_entrega(pendiente.identrega)} className='dropdown-item'>
                                <FontAwesomeIcon icon={faQrcode} /> QR
                              </a>
                            </li>
                            <li>
                              <a onClick={(e) => btn_tracking_entrega(pendiente.identrega)} className='dropdown-item'>
                                <FontAwesomeIcon icon={faTimeline} /> Tracking
                              </a>
                            </li>
                            <li>
                              <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + pendiente.nro_venta} className='dropdown-item' target='_blank' rel='noreferrer'>
                                <FontAwesomeIcon icon={faLocationArrow} /> Seguimiento
                              </a>
                            </li>
                          </ul>
                        </div>
                      </div>
                    </nav>
                  </div>
                  <div id={'collapse_pen_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionPendientes'>
                    <div className='text-dark accordion-body'>
                      {pendiente.productos.map((value, key) => (
                        <small key={key}>{`(${value.cantidad}) ${value.articulo}`}</small>
                      ))}
                      <hr></hr>
                      <small>
                        Nro Venta:{' '}
                        <a href={REACT_APP_URL_BLUEHOST + 'seguimiento/' + pendiente.nro_venta} className='btn btn-link' target='_blank' rel='noreferrer'>
                          <small>{pendiente.nro_venta}</small>
                        </a>
                      </small>
                      <br />
                      <small>Dirección: {pendiente.direccion}</small>
                      <br />
                      <small>
                        Recibe: {pendiente.direccion} | fono: {pendiente.telefono}
                      </small>
                      <br />
                      <small>Comentario: {pendiente.comentario}</small>
                      <br />
                      <Button variant='primary' className='w-100'>
                        Modificar
                      </Button>
                    </div>
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default EurekaTableroPendientes;
