import { Form } from 'react-bootstrap';
import { UseFormRegister, UseFormGetValues } from 'react-hook-form';

interface IFiltroTableroOrdenes {
  props: {
    register: UseFormRegister<IUseForm>;
    SetRefresh: Function;
    offCanvasFiltrosClose: Function;
    estados: ILabel[];
    choferes: ILabel[];
    getValues: UseFormGetValues<IUseForm>;
  };
}

interface ILabel {
  orden: number;
  descripcion: string;
  id: number;
}

interface IUseForm {
  fecha_desde: string;
  fecha_hasta: string;
  idchofer: number;
  idestado: number;
  nroorden: string;
  producto: string;
}

const FiltroTableroOrdenes: React.FC<IFiltroTableroOrdenes> = ({ props }) => {
  function submitFiltros() {
    props.SetRefresh();
    props.offCanvasFiltrosClose();
    let filtros = localStorage.getItem('filtrosordenes')?.length ? JSON.parse(localStorage.getItem('filtrosordenes') ?? '{}') : null;
    let values = props.getValues();
    if (filtros) {
      let newValues = {
        ...filtros,
        ...values,
      };
      localStorage.setItem('filtrosordenes', JSON.stringify(newValues));
    } else {
      let newValues = {
        ...values,
        idestado: values.idestado,
        idchofer: values.idchofer,
        nroorden: '',
        producto: '',
        fecha_inicio: '',
        fecha_fin: '',
      };
      localStorage.setItem('filtrosordenes', JSON.stringify(newValues));
    }
  }
  return (
    <>
      <div className='row'>
        <div className='col-md-12'>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_desde'>Fecha desde</Form.Label>
            <Form.Control type='date' id='fecha_desde' {...props.register('fecha_desde')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='fecha_hasta'>Fecha hasta</Form.Label>
            <Form.Control type='date' id='fecha_hasta' {...props.register('fecha_hasta')} />
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idestado'>Estado</Form.Label>
            <Form.Select id='idestado' {...props.register('idestado')}>
              <option value='0'>Seleccionar</option>
              {props.estados?.map((value: ILabel, key: number) => (
                <option key={key} value={value.orden}>
                  {value.descripcion}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='idchofer'>Chofer</Form.Label>
            <Form.Select id='idchofer' {...props.register('idchofer')}>
              <option value='0'>Seleccionar</option>
              {props.choferes?.map((value: ILabel, key: number) => (
                <option key={key} value={value.id}>
                  {value.descripcion}
                </option>
              ))}
            </Form.Select>
          </div>
          <div className='form-group'>
            <Form.Label htmlFor='producto'>Producto</Form.Label>
            <Form.Control type='text' id='producto' placeholder='Contiene ...' {...props.register('producto')} />
          </div>
          <div className='form-group mt-4'>
            <button onClick={submitFiltros} className='btn btn-primary w-100 btn-sm'>
              Buscar
            </button>
          </div>
        </div>
      </div>
    </>
  );
};

export default FiltroTableroOrdenes;
