import { Box, Heading, Text, Image, Anchor } from '../../components/elements';
import ErrorImage from '../../images/404.webp';

export default function Error() {
  return (
    <Box className='mc-error'>
      <Image src={ErrorImage} alt='404' />
      <Heading>¡UPS! ESTA PÁGINA NO SE PUEDE ENCONTRAR.</Heading>
      <Text>Parece que no se encontró nada en esta ubicación.</Text>
      <Anchor href='/dashboard' className='mc-btn primary' icon='home' text='volver al inicio' />
    </Box>
  );
}
