import React from 'react';
import Grid from '../estructura/EurekaGrid/EurekaGrid';

interface IStockArticulos {
  recargaGridStock: string;
  setRecargaGridStock: Function;
  offcanvasArticulos: { show: boolean; id: number; accion: string };
}

const StockArticulos: React.FC<IStockArticulos> = ({ recargaGridStock, setRecargaGridStock, offcanvasArticulos }) => {
  return <>{Grid(null, 'stock', recargaGridStock, setRecargaGridStock, offcanvasArticulos)}</>;
};
export default StockArticulos;
