import { createContext, useState } from 'react';
import { PostEureka } from '../components-new/estructura/EurekaGeneral/EurekaGeneral';

interface IFlexitProvider {
  children: React.ReactNode;
}

interface FlexitContextType {
  datos: any;
  setearDatos: (datos: any) => void;
  tokenDecifrado: (token: string) => Promise<any>;
}

export const FlexitContext = createContext<FlexitContextType | null>(null);

export const FlexitProvider: React.FC<IFlexitProvider> = ({ children }) => {
  const [datos, setDatos] = useState({});

  function setearDatos(datos: any) {
    setDatos({ ...datos });
  }
  async function tokenDecifrado(token: string) {
    const decodificacion = await PostEureka(`/Autenticacion/Decodificar`, { token: token });
    return decodificacion.content;
  }
  return <FlexitContext.Provider value={{ datos, setearDatos, tokenDecifrado }}>{children}</FlexitContext.Provider>;
};
