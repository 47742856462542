import { useState } from 'react';
import { Card } from 'react-bootstrap';
import PageLayout from '../../layouts/PageLayout';
import Grid from '../estructura/EurekaGrid/EurekaGrid';
import FormOrdenes from './Form-Ordenes';
import EurekaModals from '../estructura/EurekaModals/EurekaModals';
import EurekaOffcanvas from '../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltrosOrdenes from './Filtros-Ordenes';
import Notiflix from 'notiflix';
import moment from 'moment';
import { PutEureka } from '../estructura/EurekaGeneral/EurekaGeneral';

function Ordenes() {
  const [modalOrdenes, setmodalOrdenes] = useState({ show: false, id: 0, accion: 'a' });
  const ModalClose = () => setmodalOrdenes({ show: false, id: 0, accion: 'a' });
  const ModalShow = (id: number, accion: string) => setmodalOrdenes({ show: true, id: id, accion: accion });

  const [offcanvasOrdenes, setoffcanvasOrdenes] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasShow = (id: number, accion: string) => setoffcanvasOrdenes({ show: true, id: id, accion: accion });

  const [offcanvasFiltrosOrdenes, setoffcanvasFiltrosOrdenes] = useState({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosClose = () => setoffcanvasFiltrosOrdenes({ show: false, id: 0, accion: 'a' });
  const offCanvasFiltrosShow = (id: number, accion: string) => setoffcanvasFiltrosOrdenes({ show: true, id: id, accion: accion });

  const [recargaGridOrdenes, setRecargaGridOrdenes] = useState('');
  const [formDisabled, setformDisabled] = useState(false);

  const confirmarOrden = (origen: string, id: number) => {
    Notiflix.Confirm.show(
      'Desea confirmar esta entrega?',
      `Confirmar entrega N° #00${id}?`,
      'Si',
      'No',
      () => {
        PutEureka(`/${origen}/confirmar/${id}`, {}).then(() => {
          setRecargaGridOrdenes(moment().format('YYYY-MM-DD HH:mm:ss'));
          Notiflix.Report.success('Entrega confirmada con exito', `#00${id}`, 'Cerrar');
        });
      },
      () => {}
    );
  };

  const rechazarOrden = (origen: string, id: number) => {
    Notiflix.Confirm.show(
      'Desea rechazar esta entrega?',
      `Rechazar entrega N° #00${id}?`,
      'Si',
      'No',
      () => {
        PutEureka(`/${origen}/rechazar/${id}`, {}).then(() => {
          setRecargaGridOrdenes(moment().format('YYYY-MM-DD HH:mm:ss'));
          Notiflix.Report.success('Entrega rechazada con exito', `#${id}`, 'Cerrar');
        });
      },
      () => {}
    );
  };
  const manejo_acciones = (origen: string, registro: number, accion: string) => {
    switch (accion) {
      case 'c':
        setformDisabled(true);
        ModalShow(registro, accion);
        break;
      case 'i':
        offCanvasShow(registro, accion);
        break;
      case 'a':
        offCanvasFiltrosShow(registro, accion);
        break;
      case 'co':
        confirmarOrden(origen, registro);
        break;
      case 'ro':
        rechazarOrden(origen, registro);
        break;
      default:
        return;
    }
  };
  return (
    <>
      <PageLayout label='Adm. Ordenes'>
        <Card>
          <Card.Body>
            {Grid(manejo_acciones, 'ordenes', recargaGridOrdenes, setRecargaGridOrdenes)}
            <EurekaModals id='modal_Ordenes' manejador={modalOrdenes} modalTitulo='Consultar orden' sizeModal='xl' handleClose={ModalClose}>
              <FormOrdenes estadoOrdenes={modalOrdenes} formDisabled={formDisabled} close={ModalClose}></FormOrdenes>
            </EurekaModals>
            <EurekaOffcanvas id='offcanvas_filtrosOrdenes' manejador={offcanvasFiltrosOrdenes} offcanvasTitulo='Filtros' handleClose={offCanvasFiltrosClose} posicion='start' size='w-20'>
              <FiltrosOrdenes estadoOrdenes={offcanvasOrdenes} close={offCanvasFiltrosClose} setRecargaGridOrdenes={setRecargaGridOrdenes}></FiltrosOrdenes>
            </EurekaOffcanvas>
          </Card.Body>
        </Card>
      </PageLayout>
    </>
  );
}
export default Ordenes;
