import { Navigate } from 'react-router-dom';
interface IEurekaRediccion {
  path: string;
  element: JSX.Element;
}
const EurekaRedireccion: React.FC<IEurekaRediccion> = ({ path, element }): JSX.Element => {
  let isAutenticated = localStorage.getItem('token');
  function devolverElemento(path: string) {
    switch (path.toLowerCase()) {
      case '/':
        return isAutenticated ? <Navigate to='/dashboard' /> : element;
      case '/login':
        return isAutenticated ? <Navigate to='/dashboard' /> : element;
      default:
        return isAutenticated ? element : <Navigate to='/' />;
    }
  }
  return devolverElemento(path);
};

export default EurekaRedireccion;
