import React, { useEffect, useState } from 'react';
import Select from 'react-select';
import { useForm } from 'react-hook-form';
import { Form } from 'react-bootstrap';
import { GetEureka, PostEureka } from '../estructura/EurekaGeneral/EurekaGeneral.ts';
import { OverlayTrigger, Tooltip, Button } from 'react-bootstrap';
import moment from 'moment';

interface ICrearVentaConsignacion {
  estadoVentaconsignacion: { show: boolean; id: number; accion: string };
  formDisabled?: boolean;
  close: Function;
  setRecargaGridVentaconsignacion: Function;
}

interface IProducto {
  stock: number;
  id: number;
  label: string;
  cantidad: number;
  precio: number;
}

interface IUseForm {
  productos: IProducto[];
}

const CrearVentaconsignacion: React.FC<ICrearVentaConsignacion> = ({ estadoVentaconsignacion, formDisabled, close, setRecargaGridVentaconsignacion }) => {
  const [nuevoProducto, setNuevoProducto] = useState<IProducto>({ stock: 0, id: 0, label: '', cantidad: 0, precio: 0 });
  const [productosReact, setProductosReact] = useState<IProducto[]>([]);
  const [combos, setCombos] = useState({
    productos: [],
  });
  const { handleSubmit, setValue, getValues } = useForm<IUseForm>({
    defaultValues: {
      productos: [],
    },
  });
  useEffect(() => {
    if (!combos.productos.length) combosVentaconsignacion(estadoVentaconsignacion.id, estadoVentaconsignacion.accion);
  }, [productosReact, combos.productos.length, estadoVentaconsignacion.accion, estadoVentaconsignacion.id]);
  const combosVentaconsignacion = (registro: number, accion: string) => {
    GetEureka('/Ventaconsignacion/combos').then((response) => {
      if (response.info) {
        var registro = response.content;
        setCombos({
          productos: registro?.productos?.filter((value: any) => value.stock !== 0),
        });
      } else {
        console.log(response);
      }
    });
  };

  function submitConsignacion(e: any) {
    e.preventDefault();
    let productos = getValues().productos;
    if (!productos.find((value: any) => value.id === nuevoProducto.id)) {
      setProductosReact([...productos, { ...nuevoProducto }]);
      setValue('productos', [...productos, { ...nuevoProducto }]);
    }
  }

  function changeProduct(e: any) {
    setNuevoProducto({ stock: e.stock, id: e.value, label: e.label, cantidad: 1, precio: e.precio });
  }

  function createConsignacion() {
    const body = {
      productos: productosReact.map((value) => ({ id: value.id, cantidad: value.cantidad })),
    };
    PostEureka(`/ventaconsignacion/create`, body).then(() => {
      setRecargaGridVentaconsignacion(moment().format('YYYY-MM-DD HH:mm:ss'));
      close();
    });
  }

  const cerrar = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    close();
  };

  const renderTooltip = (props: any, valor: number) => (
    <Tooltip id='button-tooltip' {...props}>
      {`Stock maximo = ${valor}`}
    </Tooltip>
  );

  const renderTooltipName = (props: any, valor: string) => (
    <Tooltip id='button-tooltip' {...props}>
      {valor}
    </Tooltip>
  );

  const agregar = (id: number) => {
    const newArray = productosReact.map((value) => (value.id === id ? { ...value, cantidad: value.cantidad === value.stock ? value.cantidad : value.cantidad + 1 } : value));
    setProductosReact(newArray);
    setValue('productos', newArray);
  };

  const sacar = (id: number) => {
    const newArray = productosReact.map((value) => (value.id === id ? { ...value, cantidad: value.cantidad === 0 ? value.cantidad : value.cantidad - 1 } : value));
    setProductosReact(newArray);
    setValue('productos', newArray);
  };

  const eliminarProducto = (id: number) => {
    const newArray = productosReact.filter((value) => value.id !== id);
    setProductosReact(newArray);
    setValue('productos', newArray);
  };

  return (
    <div className='d-flex flex-column justify-content-between height-100'>
      <form className='row height-100' onSubmit={handleSubmit(createConsignacion)}>
        <div className='col-md-12'>
          <div className='col-12'>
            <Form.Label htmlFor='articulo'>Productos</Form.Label>
            <Select defaultValue={{ value: 0, label: 'Select...', name: 'idproducto' }} options={[{ value: 0, label: 'Select...', name: 'idproducto' }, ...combos.productos.map((producto: any) => ({ value: producto.id, label: producto.descripcion, name: 'idproducto', precio: producto.precio_venta, stock: producto.stock }))]} onChange={changeProduct} />
            <button className='btn btn-sm btn-filtro-flexit mt-2' onClick={submitConsignacion}>
              Agregar
            </button>
          </div>
          <div className='alto-creacion border rounded p-1 mt-1'>
            {productosReact.map((value, key) => {
              let precio = value.precio * value.cantidad;
              return (
                <div key={key} className='d-flex align-items-center justify-content-between mt-2 mb-2 border rounded p-1'>
                  <OverlayTrigger placement='right' delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltipName(props, value.label)}>
                    <h6>{value?.label.slice(0, 8) + '...'}</h6>
                  </OverlayTrigger>
                  <div className='btn-group'>
                    <Button className='btn-sm' onClick={() => sacar(value.id)}>
                      -
                    </Button>
                    <Button className='btn-sm'>{value.cantidad}</Button>
                    <OverlayTrigger placement='left' delay={{ show: 250, hide: 400 }} overlay={(props) => renderTooltip(props, value.stock)}>
                      <Button className='btn-sm' onClick={() => agregar(value.id)}>
                        +
                      </Button>
                    </OverlayTrigger>
                  </div>
                  <h5>{`${precio.toFixed(2)}$`}</h5>
                  <Button className='btn-sm' variant='danger' onClick={() => eliminarProducto(value.id)}>
                    X
                  </Button>
                </div>
              );
            })}
          </div>
          <h2>{`Total: ${productosReact.reduce((a: any, c) => (c.precio * c.cantidad + a !== undefined ? c.precio * c.cantidad : 0), 0).toFixed(2)}$`}</h2>
        </div>
        <div className='d-flex justify-content-end align-items-end botones-offcanvas'>
          <button className='btn btn-sm background-roj' onClick={cerrar}>
            Cerrar
          </button>
          <button type='submit' className='btn background-ver btn-sm'>
            Crear
          </button>
        </div>
      </form>
    </div>
  );
};

export default CrearVentaconsignacion;
