import { useEffect, useState } from 'react';
import EurekaTableroPendientes from '../Entregas/EurekaTableroPendientes';
import EurekaTableroFinalizadas from '../Entregas/EurekaTableroFinalizadas';
import EurekaOffcanvas from '../../estructura/EurekaOffCanvas/EurekaOffCanvas';
import FiltroTableroEntregas from './FiltroEntregas';
import { GetEureka, PostEureka } from '../../estructura/EurekaGeneral/EurekaGeneral';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faArrowsRotate, faTableColumns, faTableList, faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useForm } from 'react-hook-form';
import PageLayout from '../../../layouts/PageLayout';
import moment from 'moment';
import { Button, ButtonGroup, OverlayTrigger, Tooltip } from 'react-bootstrap';
const { REACT_APP_DIAS_DASHBOARD } = process.env;

var fecha = moment();
const fechaDesde = moment(fecha).subtract(REACT_APP_DIAS_DASHBOARD, 'days').format('YYYY-MM-DD');
const fechaHasta = moment(fecha).add(1, 'days').format('YYYY-MM-DD');

interface IUseForm {
  fecha_desde: string;
  fecha_hasta: string;
  idtipoconector: number;
  idtipoentrega: number;
  idestado: number;
  nro_venta: string;
  producto: string;
}

interface ICombos {
  id: number;
  descripcion: string;
  orden: number;
}

export default function TableroEntregas() {
  const [directionTablero, setDirectionTablero] = useState<boolean>(true);
  const [visibleFinalizados, setVisibleFinalizados] = useState<boolean>(true);
  const [refresTablero, setRefresTablero] = useState<moment.Moment>();
  const [pendientes, setPendientes] = useState([]);
  const [finalizados, setFinalizados] = useState([]);
  const [estados, setEstados] = useState<ICombos[]>([]);
  const [tipoConector, setTipoConector] = useState<ICombos[]>([]);
  const [tipoEntregas, setTipoEntregas] = useState<ICombos[]>([]);
  const SetRefresh = () => setRefresTablero(moment());
  const SetDirection = () => setDirectionTablero(!directionTablero);
  const SetVisibleFina = () => setVisibleFinalizados(!visibleFinalizados);
  const { register, getValues, setValue } = useForm<IUseForm>({
    defaultValues: {
      fecha_desde: fechaDesde,
      fecha_hasta: fechaHasta,
      idtipoconector: 0,
      idtipoentrega: 0,
      idestado: 0,
      nro_venta: '',
      producto: '',
    },
  });
  const [offcanvasFiltros, setoffcanvasFiltros] = useState({ show: false });
  const offCanvasFiltrosClose = () => setoffcanvasFiltros({ show: false });
  const offCanvasFiltrosShow = () => setoffcanvasFiltros({ show: true });
  function underline(e: any) {
    e.target.className = `${e.target.className} text-decoration-underline`;
  }
  function noUnderline(e: any) {
    e.target.className = `${e.target.className.split('text-decoration-underline').join('')}`;
  }
  const tooltipDirection = (props: any) => <Tooltip id='button-tooltip' {...props}>{`Cambiar tablero a ${!directionTablero ? 'Filas' : 'Columnas'}`}</Tooltip>;
  const tooltipVisible = (props: any) => <Tooltip id='button-tooltip' {...props}>{`${!visibleFinalizados ? 'Ocultar' : 'Ver'} finalizados`}</Tooltip>;
  const tooltipRefresh = (props: any) => (
    <Tooltip id='button-tooltip' {...props}>
      Actualizar tablero
    </Tooltip>
  );
  useEffect(() => {
    var formdata = getValues();
    let filtrosTableroEntregas = localStorage.getItem('filtrosentregas')?.length ? JSON.parse(localStorage.getItem('filtrosentregas') ?? '') : null;
    if (filtrosTableroEntregas) {
      setValue('fecha_desde', filtrosTableroEntregas.fecha_desde);
      setValue('fecha_hasta', filtrosTableroEntregas.fecha_hasta);
      setValue('idtipoconector', filtrosTableroEntregas.idtipoconector);
      setValue('idtipoentrega', filtrosTableroEntregas.idtipoentrega);
      setValue('idestado', typeof filtrosTableroEntregas.idestado === 'object' ? 0 : filtrosTableroEntregas.idestado);
      setValue('nro_venta', filtrosTableroEntregas.nro_venta);
      setValue('producto', filtrosTableroEntregas.producto);
      if (typeof filtrosTableroEntregas.idestado === 'object') filtrosTableroEntregas.idestado = 0;
      PostEureka('/entregas/tablero', { ...filtrosTableroEntregas, idtipoconector: [filtrosTableroEntregas.idtipoconector], idestado: [filtrosTableroEntregas.idestado] }).then((response) => {
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
    } else {
      PostEureka('/entregas/tablero', { ...formdata, idtipoconector: [formdata.idtipoconector], idestado: [formdata.idestado] }).then((response) => {
        setPendientes(response.content.pendientes);
        setFinalizados(response.content.finalizados);
      });
    }
    GetEureka('/entregas/combos').then((response) => {
      console.log(response.content);
      setEstados(response.content.estados);
      setTipoConector(response.content.tipo_conector);
      setTipoEntregas(response?.content?.tipo_entregas);
    });
  }, [refresTablero, getValues, setValue]);

  return (
    <>
      <PageLayout label='Adm. Entregas'>
        <EurekaOffcanvas id='off-canvas-entregas' manejador={offcanvasFiltros} offcanvasTitulo='Filtros entregas' posicion='start' handleClose={offCanvasFiltrosClose} size='eureka-offcanvas'>
          <FiltroTableroEntregas props={{ register, SetRefresh, getValues, offCanvasFiltrosClose, estados, tipoConector, tipoEntregas }} />
        </EurekaOffcanvas>
        <div className='row'>
          <div className='col-md-1 mb-2'>
            <ButtonGroup>
              <Button size='sm' onClick={offCanvasFiltrosShow} variant='outline-primary'>
                Filtros
              </Button>
              <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipRefresh}>
                <Button size='sm' onClick={SetRefresh} variant='outline-primary'>
                  <FontAwesomeIcon icon={faArrowsRotate} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipDirection}>
                <Button size='sm' onClick={SetDirection} variant='outline-primary'>
                  <FontAwesomeIcon icon={!directionTablero ? faTableList : faTableColumns} />
                </Button>
              </OverlayTrigger>
              <OverlayTrigger placement='right' delay={{ show: 150, hide: 150 }} overlay={tooltipVisible}>
                <Button size='sm' onClick={SetVisibleFina} variant='outline-primary'>
                  <FontAwesomeIcon icon={visibleFinalizados ? faEye : faEyeSlash} />
                </Button>
              </OverlayTrigger>
            </ButtonGroup>
          </div>
        </div>
        <div className={`${directionTablero ? 'column' : 'row'} justify-content-center`}>
          <EurekaTableroPendientes direction={directionTablero} pendientes={pendientes} underline={underline} noUnderline={noUnderline} SetRefresh={SetRefresh} />
          <EurekaTableroFinalizadas visible={visibleFinalizados} direction={directionTablero} finalizados={finalizados} underline={underline} noUnderline={noUnderline} SetRefresh={SetRefresh} />
        </div>
      </PageLayout>
    </>
  );
}
