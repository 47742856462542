import { Badge } from 'react-bootstrap';
import $ from 'jquery';

interface IEurekaOrdenesPendientes {
  pendientes: IPendiente[];
  SetRefresh: Function;
  direction: boolean;
  hidden: boolean;
}

interface IPendiente {
  estado: string;
  nro_venta: string;
  idorden: number;
  nroorden: string;
  chofer: string;
  sucursal: string;
  total: number;
  idestado: number;
}

const EurekaOrdenesPendientes: React.FC<IEurekaOrdenesPendientes> = ({ pendientes = [], SetRefresh, direction = true, hidden }) => {
  function checkallpendientes() {
    if ($('.all_chk_pendientes').is(':checked')) {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]').forEach((input) => {
        input.checked = true;
      });
    } else {
      document.querySelectorAll<HTMLInputElement>('[class^="chk_p_"]').forEach((input) => {
        input.checked = false;
      });
    }
  }
  return (
    <div hidden={hidden} className={direction ? 'col-md-12 mt-4' : 'col-md-4'}>
      <>
        {/*                 <EurekaModals manejador={modalPendiente} modalTitulo={modalTitulo} sizeModal={EurekaGeneral.tamanio_modal_tablero(chequeosPendientes)} handleClose={ModalClose}>
                    {
                        modalPendiente.tipo === 1 ? (<FormCanceladosEntregas ListaChecks={chequeosPendientes} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                            modalPendiente.tipo === 2 ? (<FormTrackingEntregas identrega={identregaActivo} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                                modalPendiente.tipo === 3 ? (<FormConfirmadosEntregas ListaChecks={chequeosPendientes} identrega={identregaActivo} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                                    modalPendiente.tipo === 4 ? (<FormImprimirEntregas ListaChecks={chequeosPendientes} identrega={identregaActivo} SetRefresh={SetRefresh} ModalClose={ModalClose} />) :
                                        modalPendiente.tipo === 5 ? (<FormQrEntregas identrega={entregaActivo.identrega} />) :
                                            (<div className="alert alert-danger" role="alert">No hay entregas seleccionadas</div>)
                    }
                </EurekaModals> */}
        <div>
          <div className='card col_entregas_pendientes'>
            <div className='card-header eureka-bg-primary'>
              <div className='row'>
                <div className='col-10 text-light'>
                  <input className='all_chk_pendientes' onClick={checkallpendientes} type='checkbox' /> Pendientes <Badge bg='primary'>{pendientes.length}</Badge>
                </div>
                <div className='col-2 text-end'>
                  <div className='dropdow'>
                    <button className='btn btn-primary btn-sm dropdown-toggle' type='button' data-bs-toggle='dropdown' aria-expanded='false'>
                      +
                    </button>
                    <ul className='dropdown-menu'></ul>
                  </div>
                </div>
              </div>
            </div>
            <div className='card-body'>
              <div className={`accordion ${direction ? 'row' : ''}`} id='accordionPendientes'>
                {pendientes.map((pendiente, key) => (
                  <div className={`accordion-item ${direction ? 'col-md-4' : ''}`} key={key}>
                    <div className='accordion-header eureka-bg-primary accordion-pendiente'>
                      <nav className='navbar bg-body-tertiary'>
                        <div className='container-fluid'>
                          <div className='form-check'>
                            <input className={'chk_p_' + key + ' form-check-input me-1'} data-nroventa={pendiente.nro_venta} type='checkbox' value={pendiente.idorden} id={'chk_pen_' + key} />
                            <label className='text-ligth form-check-label f-13' data-bs-toggle='collapse' data-bs-target={'#collapse_pen_' + key} aria-expanded='false' aria-controls={'collapse_pen_' + key}>
                              #{pendiente.nroorden} - {pendiente.estado}
                            </label>
                          </div>
                        </div>
                      </nav>
                    </div>
                    <div id={'collapse_pen_' + key} className='accordion-collapse collapse' data-bs-parent='#accordionPendientes'>
                      <div className='text-dark accordion-body'>
                        {/* <small>Origen: {pendiente.nro_venta}</small><br /> */}
                        <small>Chofer: {pendiente.chofer}</small>
                        <br />
                        <small>Sucursal: {pendiente.sucursal}</small>
                        <br />
                        <small>Cantidad: {pendiente.total}</small>
                        <br />
                        {/* <Button size="sm pt-0 pb-0 w-100" variant="primary">Modificar</Button> */}
                      </div>
                    </div>
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>
      </>
    </div>
  );
};

export default EurekaOrdenesPendientes;
