import React from 'react';
import { List, Item, Anchor, Button } from '../elements';
import { useNavigate, useLocation } from 'react-router-dom';

interface IMenuItem {
  item: {
    href: string;
    icon: string;
    text: string;
    hide?: boolean;
    submenu?: {
      href: string;
      text: string;
    }[];
    badge?: {
      variant: string;
      text: string;
    };
  };
}

const MenuItem: React.FC<IMenuItem> = ({ item }) => {
  const navigate = useNavigate();
  const location = useLocation();
  const activar = location.pathname.split('/')[1].toLowerCase();
  function changeText(text: string) {
    switch (text) {
      case 'cuenta corriente':
        return 'ccorriente';
      case 'venta consignacion':
        return 'ventaconsignacion';
      default:
        return text;
    }
  }
  return (
    <Item className={`mc-sidebar-menu-item ${activar === changeText(item.text) ? 'active' : ''}`} onClick={() => {}}>
      {item.submenu ? (
        <>
          <Button
            icon={item.icon}
            text={item.text}
            badge={item.badge}
            hidden={item.hide}
            onClick={() => {
              navigate(`${item.text === 'ordenes' ? '/Ordenes' : '/Entregas'}`);
            }}
            arrow='expand_more'
            className='mc-sidebar-menu-btn'
          />
          <List className='mc-sidebar-dropdown-list'>
            {item.submenu.map((item, index) => (
              <Item key={index} className='mc-sidebar-dropdown-item'>
                <Anchor href={item.href} className='mc-sidebar-dropdown-link'>
                  {item.text}
                </Anchor>
              </Item>
            ))}
          </List>
        </>
      ) : (
        <Anchor hidden={item.hide} href={item.href} icon={item.icon} text={item.text} badge={item.badge} className='mc-sidebar-menu-btn' />
      )}
    </Item>
  );
};

export default MenuItem;
