import React from 'react';

interface IOption {
  children: React.ReactNode;
  value?: number;
}

const Option: React.FC<IOption> = ({ children, value }) => {
  return <option value={value?.toString()}>{children}</option>;
};

export default Option;
